import { combineReducers } from 'redux';
import { call, all } from 'redux-saga/effects';

import campaignGame, { campaignGameSagas } from './campaignGame';
import cardsList, { cardsListSagas } from './cardsList';
import map, { mapSagas } from './map';
import round, { roundSagas } from './round';
import player, { playerSagas } from './player';
import { lifecycleSagas } from './lifecycle';
import { connectRouter } from 'connected-react-router';

export const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  campaignGame,
  cardsList,
  map,
  round,
  player,
});

export function* rootSaga() {
  yield all([
    call(campaignGameSagas),
    call(cardsListSagas),
    call(playerSagas),
    call(mapSagas),
    call(roundSagas),
    call(lifecycleSagas),
  ]);
}
