import { withHandlers } from 'recompose';

const byDate = (cardA, cardB) => cardA.date - cardB.date > 0 ? 1 : -1
const compareWith = 
  cardsDetails => 
  (isOrdered, currentCard, cardIndex) => 
  isOrdered && currentCard.date === cardsDetails[cardIndex].date
const handlers = {
  checkCardsOrder: props => cardsDetails => {
    const { handleFoundOrder, handleWrongOrder } = props;

    const orderedCards = [...cardsDetails].sort(byDate);

    const doDatesMatch = orderedCards.reduce(compareWith(cardsDetails), true);

    if (doDatesMatch) {
      try {
        handleFoundOrder();
      }
      catch (e) {
        console.error(e);
      }
    } else {
      handleWrongOrder();
    }
  },
};

export default withHandlers(handlers);
