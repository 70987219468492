import { connect } from 'react-redux';

import { 
    actions as mapActions,
    selectors as mapSelectors,
} from 'features/map';
import { selectors as playerSelectors } from 'features/player';
import { selectors as roundSelectors } from 'features/round';

const mapStateToProps = state => ({
    hasPlayerUnlockedSavedGames: playerSelectors.hasPlayerUnlockedSavedGames(state),
    map: mapSelectors.getCurrentMap(state),
    player: playerSelectors.getPlayerSelector(state),
    round: roundSelectors.getRoundParams(state),
});

const mapDispatchToProps = { debriefLevel: mapActions.debriefLevel };

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;