import { compose } from 'recompose';

import WizardModal from './WizardModal.design';
import withConnect from './WizardModal.connect';
import withStateHandlers from './WizardModal.stateHandlers';
import withAsyncHandlers from './WizardModal.asyncHandlers';
import withLifecycle from './WizardModal.lifecycle';

export default compose(
    withConnect,
    withStateHandlers,
    withAsyncHandlers,
    withLifecycle,
)(WizardModal);
