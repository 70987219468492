import { createReducer } from '@reduxjs/toolkit';

import initialState from './initialState';

import { types } from '.'
import { types as mapTypes } from 'features/map'
import { types as campaignGameTypes } from 'features/campaignGame'

const playerReducer = createReducer(initialState, {
  [types.ROUND_SET_PROPERTIES]: (state, action) => ({
    ...state,
    numberOfAttempts: action.payload.numberOfAttempts,
    numberOfMoves: action.payload.numberOfMoves,
    isFirstRound: true,
  }),
  [campaignGameTypes.CAMPAIGN_GAME_INIT]: () => ({ ...initialState }),
  [mapTypes.MAP_START_GAME]: () => ( { ...initialState, isFirstRound: true }),
  [types.ROUND_SET_PROPERTIES]: (state, action) => ({
    ...state,
    numberOfAttempts: action.payload.numberOfAttempts,
    numberOfMoves: action.payload.numberOfMoves,
    isFirstRound: true,
  }),
  [types.ROUND_FOUND_ORDER]: state => ({ ...state, areCardsOrdered: true }),
  [types.ROUND_WRONG_ORDER]: state => ({ ...state, numberOfAttempts: state.numberOfAttempts + 1, showMissMessage: true }),
  [types.ROUND_MOVE_CARD]: state => ({ ...state, numberOfMoves: state.numberOfMoves + 1, isFirstRound: false }),
  [types.ROUND_RESET]: state => ({
    ...state,
    areCardsOrdered: false,
    numberOfMoves: 0,
    cardDragged: null,
    startTime: new Date(),
  }),
  [types.ROUND_SET_DRAG_CARD]: (state, action) => ({ ...state, cardDragged: action.payload }),
  [types.ROUND_CLEAR_DRAG_CARD]: state => ({ ...state, cardDragged: null }),
  [types.ROUND_CLOSE_WRONG_ORDER_MODAL]: state => ({ ...state, showMissMessage: false }),
  [types.ROUND_CLOSE_OUT_OF_ENERGY_MODAL]: state => ({ ...state, outOfEnergy: false }),
  [types.ROUND_CLOSE_OUT_LIVES]: state => ({ ...state, outOfEnergy: false }),
  [types.ROUND_OUT_OF_ENERGY]: state => ({ ...state, outOfEnergy: true}),
  [types.ROUND_OUT_OF_TIME]: state => ({ ...state, outOfTime: true}),
  [mapTypes.MAP_DEBRIEF_LEVEL]: state => ({ ...state, areCardsOrdered: false}),
});

export default playerReducer;
