import { createReducer } from '@reduxjs/toolkit';

import initialState from './initialState';

import { types } from '.';

const campaignGameReducer = createReducer(initialState, {
  [types.CAMPAIGN_GAME_INIT]: () => ({ ...initialState }),
});

export default campaignGameReducer;