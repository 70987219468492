import React, { Fragment } from 'react';
import Control from 'react-leaflet-control';
import { Button, GameMapIcon } from 'components';
import { SIZE_THREE_QUARTERS, BUTTON_SAVE_ACCOUNT } from 'components/Button/Button.constants';
import L from 'leaflet';

import { Icon, Point, CRS } from 'leaflet';
import { Map, ImageOverlay, Marker, Polyline, FeatureGroup, Circle } from 'react-leaflet';

import styles from "./GameMap.module.scss";
import mapPicture from './assets/tutorial-map.jpg';

const instanciateYouAreHereIcon = () => ({
  key: 'youAreHere',
  leafletIcon: new Icon({
      // eslint-disable-next-line
      iconUrl: require(`./assets/arrow-current-level-smaller.png`),
      iconSize: new Point(30, 30),
      className: styles.youAreHereIcon,
  })
});

const renderNextLevels = (mapPosition) => {
  const icon = instanciateYouAreHereIcon();
  return (<Marker
    key={icon.key}
    position={mapPosition && ([mapPosition[0] + 20, mapPosition[1]])}
    icon={icon.leafletIcon}
    zIndexOffset={1000000}
  ></Marker>
  );
}

const renderUnlockedLevels = (player, handleMarkerClick) => player?.unlockedMapCoords?.map(
  mapCoords => (
    <GameMapIcon
      key={mapCoords.id}
      levelId={mapCoords.id}
      levelName={mapCoords.levelName}
      levelNumber={mapCoords.level}
      mapPosition={mapCoords.mapPosition}
      iconType={mapCoords.iconType}
      player={player}
      isUnlocked={true}
      handleMarkerClick={handleMarkerClick}
    />)
);

const renderLockedLevels = player => player?.lockedMapsPositions?.map(
  mapCoords => (
    <GameMapIcon
      key={mapCoords.id}
      levelId={mapCoords.id}
      levelNumber={mapCoords.level}
      mapPosition={mapCoords.mapPosition}
      iconType={mapCoords.iconType}
      player={player}
      isUnlocked={false}
      handleMarkerClick={() => {}}
    />)
);

const renderPaths = ([coordsStart, coordsEnd, idStart, idEnd], index) => (
  <Fragment key={`${idStart}-${idEnd}-${index}`}>
    <Polyline
      positions={[coordsStart, coordsEnd]}
      className={styles.myPolylineStroke}
    />
    <Polyline
      positions={[coordsStart, coordsEnd]}
      className={styles.myPolyline}
    />
    <Polyline
      positions={[coordsStart, coordsEnd]}
      className={styles.myPolylineInner}
    />
    <Polyline
      positions={[coordsStart, coordsEnd]}
      className={styles.myPolylineOuter}
    />
  </Fragment>
);


const GameMap = ({ player, mapLocationFetch, history, currentPlayerLocation, saveAccount, hasPlayerUnlockedSavedGames, circleRef }) => {
  const nextLevelsLocations = player
    ?.unlockedMapCoords
    ?.filter(mapCoord => !player.wonLevels[mapCoord.id])
    .map(mapLocation => mapLocation.mapPosition);

  const pathsCoords = player
    ?.unlockedMapCoords
    ?.flatMap(mapCoord => 
      mapCoord
      .unlockLevels
      .map(levelId =>
        [
          mapCoord.mapPosition,
          player
            .unlockedMapCoords
            .find(level =>
              levelId === level.id
            )
            ?.mapPosition,
            mapCoord.id,
            levelId
        ])
  )
  .filter(tuple => tuple[0] !== undefined && tuple[1] !== undefined)

  return (
    <div className={styles.mapWrapper}>
      <Map
        id="myMap"
        center={currentPlayerLocation}
        className={styles.map}
        attributionControl={false}
        crs={CRS.Simple}
        doubleClickZoom={false}
        maxBounds={[[0, 0], [3072, 4096]]}
        maxBoundsViscosity={1.0}
        scrollWheelZoom={false}
        zoom={1}
        zoomControl={false}
        onClick={e => console.log(e.latlng)}
        renderer={new L.SVG({ padding: 1 })}
      >
        <Control position="topright" >
          <Button
            size={SIZE_THREE_QUARTERS}
            onClick={() => history.push('/')}
          />

          {hasPlayerUnlockedSavedGames && !player.isRegistered &&
            <Button
              type={BUTTON_SAVE_ACCOUNT}
              size={SIZE_THREE_QUARTERS}
              onClick={saveAccount}
            />}
        </Control>
        <defs id="myDefinitions">
          <mask id="mask2" x="0" y="0" width="100" height="100" >
            <circle cx="25" cy="25" r="25" style={{ stroke: 'none', fill: '#ffffff' }} />
          </mask>
        </defs>

        <Circle fill={false} ref={circleRef} id="myCircleMask" className="myCircleMask" center={{ lat: 595, lng: 660 }} radius={90}></Circle>
        <rect x="1" y="1" width="100" height="100"
          style={{ stroke: 'none', fill: '#0000ff', mask: 'url(#mask2)' }} />

        <ImageOverlay url={mapPicture} bounds={[[0, 0], [3072, 4096]]} zIndex={0} />
        {nextLevelsLocations?.map(renderNextLevels)}
        {renderUnlockedLevels(player, mapLocationFetch)}
        {renderLockedLevels(player)}
        <FeatureGroup>
          {pathsCoords?.map(renderPaths)}
        </FeatureGroup>
      </Map>
    </div>
  );
}

export default GameMap;
