import {
  Game,
  Menu,
  Round,
  ViewCards,
  Collection,
} from 'pages';

export default [
  { path: '/', exact: true, component: Menu },
  { path: '/game', exact: true, component: Game },
  { path: '/round', exact: true, component: Round },
  { path: '/cards', exact: true, component: ViewCards },
  { path: '/collection', exact: true, component: Collection },
];
