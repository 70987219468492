export const getGameStrength = (state, props) => state.player.gameStrength;
export const getPlayerLevel = (state, props) => state.player.playerLevel;
export const getPlayerSelector = (state, props) => state.player;
export const getCurrentRoundSelector = (state, props) => state.player?.currentRound;
export const getPlayerName = (state) => state.player.name;

export const getCurrentPlayerLocation = (state, props) => {
    const player = state?.player?.unlockedMapCoords?.find(
    mapLocation => {
            if (mapLocation === undefined) {/* TODO: check error */ }
            return mapLocation?.id === state?.player?.currentMapLocation
        }
    )?.mapPosition;
    return player;
}

const SUCCESS_ENABLE_SAVES = '9808fc51-30b1-4e9f-bbcb-b3dd4dcdd325';

export const hasPlayerUnlockedSavedGames = (state) => !!(state?.player?.successes?.[SUCCESS_ENABLE_SAVES]);
