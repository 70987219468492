import React from 'react';

import { NB_MAX_LIVES } from 'configuration/parameters';

import styles from './LostRound.module.scss';

const LostRound = ({ round, history, campaignGameInit }) =>
  round.showMissMessage && round.numberOfAttempts >= NB_MAX_LIVES && (
    <div className={styles.lostRoundWrapper} onClick={() => {
      campaignGameInit();
    }}>
      <div className={styles.lostRound}>
        <p className={styles.lostRoundText}>PLUS DE VIE!</p>
        <p className={styles.lostRoundDetails}><ul>
          <li>Score total : 42142</li>
          <li>Points d'expérience gagnés: 42</li>
          <li>Progression prochaine carte : 42%</li>
        </ul></p>
        <div className={styles.lostRoundButtonText}>Rejoue!</div>
      </div>
    </div>
    );

export default LostRound;
