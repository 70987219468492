import React from 'react';

import { Collection } from 'components';
import styles from './Collection.module.scss';

const CollectionPage = () => (
  <div className={styles.collection}>
    <Collection />
  </div>
);

export default CollectionPage;