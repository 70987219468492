import React from 'react';

import { MenuButton } from 'components';

import styles from './CheckButton.module.scss';

const CheckButton = ({ checkCardsOrder }) => (
  <div className={styles.checkButtonWrapper}>
    <MenuButton onClick={checkCardsOrder}>Vérifie!</MenuButton>
  </div>);

export default CheckButton;
