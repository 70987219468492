import { createReducer } from '@reduxjs/toolkit';

import { types } from '.';

const initialState = {};

const cardsListReducer = createReducer(initialState, {
  [types.CARDS_LIST_FETCH_SUCCESS]: (_, action) => ({...action.payload}),
});

export default cardsListReducer;
