import { lifecycle } from 'recompose';
import { setJwtToken, getJwtToken } from 'services/localStorage';
import { registerClient } from 'services/http';

export default lifecycle({
    async componentDidMount() {
        const token = getJwtToken();
        if (!token) {
            try {
                const clientInitResponse = await registerClient();
                setJwtToken(clientInitResponse.data.token);
            } catch (error) {
                console.error(error);
            }
        }
    }
});