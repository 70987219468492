import {
  ROUND_FOUND_ORDER,
  ROUND_WRONG_ORDER,
  ROUND_CLOSE_OUT_OF_ENERGY_MODAL,
  ROUND_CLOSE_WRONG_ORDER_MODAL,
  ROUND_OUT_OF_ENERGY,
  ROUND_OUT_OF_LIVES,
  ROUND_OUT_OF_TIME,
} from './types';

export const handleFoundOrder = () => ({
  type: ROUND_FOUND_ORDER,
});

export const handleWrongOrder = () => ({
  type: ROUND_WRONG_ORDER,
});

export const closeWrongOrderModal = () => ({
  type: ROUND_CLOSE_WRONG_ORDER_MODAL,
});

export const closeOutOfEnergyModal = () => ({
  type: ROUND_CLOSE_OUT_OF_ENERGY_MODAL,
});

export const handleOutOfEnergy = () => ({
  type: ROUND_OUT_OF_ENERGY,
});

export const handleOutOfLives= () => ({
  type: ROUND_OUT_OF_LIVES,
});

export const handleOutOfTime = () => ({
  type: ROUND_OUT_OF_TIME,
});