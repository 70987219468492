import { lifecycle } from 'recompose';

export default lifecycle({
    componentDidMount() {
      const {timerId, processNextDiscussion} = this.props;
      this.props.startTimer({timerId}, {processNextDiscussion})
    },

    componentWillUnmount() {
        this.props.stopTimer();
        // this.props.mode === 'post' && this.props.showCardsRewards();
    }
  });
