import { connect } from 'react-redux';

import { selectors as mapSelectors } from 'features/map';
import { selectors as playerSelectors } from 'features/player';
import { selectors as roundSelectors } from 'features/round';

const mapStateToProps = (state) => ({
  map: mapSelectors.getCurrentMap(state),
  player: playerSelectors.getPlayerSelector(state),
  round: roundSelectors.getRoundParams(state),
});

const withConnect = connect(mapStateToProps, null, null, { pure: false });

export default withConnect;
