import React from 'react';

import { SortGameCommon } from 'components';

const SortGameCampaign = (props) => {
  const { 
  handleFoundOrder,
  handleWrongOrder,
  handleOutOfTime,
  handleOutOfEnergy,
  campaignGameInit,
  round, player, map,
  cardsDetails,
  numberOfAttempts,
  uuid,
  startTimeStamp,
  numberOfMoves,
} = props;
  return (cardsDetails.length > 0 && <SortGameCommon
    round={round}
    player={player}
    map={map}
    handleFoundOrder={handleFoundOrder}
    handleWrongOrder={handleWrongOrder}
    handleOutOfTime={handleOutOfTime}
    handleOutOfEnergy={handleOutOfEnergy}
    campaignGameInit={campaignGameInit}

    cardsDetails={cardsDetails}
    numberOfAttempts={numberOfAttempts}
    numberOfMoves={numberOfMoves}
    uuid={uuid}
    startTimeStamp={startTimeStamp}
/>)
};

export default SortGameCampaign;
