import { select, put } from 'redux-saga/effects';
import * as actions from './actions';
import { NB_MAX_ENERGY, NB_MAX_LIVES }  from 'configuration/parameters';

import { getCurrentMap } from 'features/map/selectors';
import { getRoundParams } from 'features/round/selectors';

export function* checkCardsOrderSaga() {
}

export function* checkEnergyLeft() {
    const round = yield select(getRoundParams);
    const map = yield select(getCurrentMap);
    const nbMaxEnergy = map.maxEnergy ? map.maxEnergy : NB_MAX_ENERGY;
    if ( !map.disableEnergy && round.numberOfMoves >= nbMaxEnergy) {
        yield put(actions.handleOutOfEnergy());
    }
}

export function* checkLivesLeft() {
    const round = yield select(getRoundParams);
    const map = yield select(getCurrentMap);
    if ( !map.disableLives && round.numberOfAttempts >= NB_MAX_LIVES) {
        yield put(actions.handleOutOfLives());
    }
}

export function* watchRound() {
}
