import { connect } from 'react-redux';
import { selectors as mapSelectors } from 'features/map';
import { selectors as playerSelectors } from 'features/player';
import { selectors as roundSelectors } from 'features/round';
const mapStateToProps = state => ({
  map: mapSelectors.getCurrentMap(state),
  round: roundSelectors.getRoundParams(state),
  player: playerSelectors.getPlayerSelector(state),
});
const withConnect = connect(mapStateToProps, {}, null, { pure: false });

export default withConnect;