import React from 'react';

import { NB_MAX_LIVES } from 'configuration/parameters';

import styles from './WrongOrder.module.scss';

const WrongOrder = ({ round, closeWrongOrderModal, map }) => {
  return round.showMissMessage && (map.disableLives || round.numberOfAttempts < NB_MAX_LIVES) && (
    <div className={styles.wrongOrderWrapper} onClick={closeWrongOrderModal}>
      <div className={styles.wrongOrder}>
        <p className={styles.wrongOrderText}>RATÉ !</p>
        <p className={styles.wrongOrderDetails}><ul className={styles.wrongOrderDetailsList}>
          {map?.customErrorMessage && <li>{map.customErrorMessage}</li>}
          {(!map || !map.disableLives) && (
            <li>
              <span className={styles.heartIcon}>&nbsp;</span> Reste {NB_MAX_LIVES - round.numberOfAttempts} vie{(NB_MAX_LIVES - round.numberOfAttempts > 1) && 's'}
            </li>
          )}
        </ul></p>
      </div>
    </div>
    );
}

export default WrongOrder;
