import React from 'react';

import { LivesBar, StrengthBar } from 'components';

import styles from './EpochGameHeader.module.scss';

const EpochGameHeader = ({
  lives,
  strength,
}) => (

    <div className={styles.topArea}>
      <LivesBar value={lives} />
      <StrengthBar value={strength} />
    </div>
  );

export default EpochGameHeader;