import React from 'react';

import { Card } from 'components';

import styles from './Collection.module.scss';

const cardsByName = (cardA, cardB) => cardA[1].name.toLocaleLowerCase() < cardB[1].name.toLocaleLowerCase() ? -1 : 1;


const renderCard = isGrayed => ([cardId, card], index) => {
  return (
  <div
    className={styles.cardWrapper}
    key={cardId}
  >
    <Card
      disableDrag={true}
      isGrayed={isGrayed}
      id={cardId}
      index={index}
      name={card.name}
      image={card.illustration}
      date={card.date}
      dateLabel={card.dateLabel}
      description={card.description}
      value={1}
      theme={card.theme} />
  </div>
);
  }

const collectedCards = player => ([cardId, card]) => player?.collection?.find(card => cardId === card.cardId);
const unCollectedCards = player => ([cardId, card]) => player?.collection?.find(card => cardId === card.cardId) === undefined;

const Collection = (props) => {
  const { cardsList, player, history } = props;
              
  //const existingCardForPlayer = player?.collection?.find(card => cardId === card.cardId);

  return (
    <div className={styles.collection}>
      <div className={styles.collectionHeader}>
        <span className={styles.collectionHeaderGray}>Ma collection</span>Ma collection
      </div>
      <input type="image" src="/ui/home-button.png" className={styles.homeButton} onClick={() => history.push('/')} alt="Menu" />
      <h3 className={styles.sectionHeader}>Cartes collectées</h3>
      <div className={styles.viewCards}>
        {cardsList &&
          Object
            .entries(cardsList)
            .sort(cardsByName)
            .filter(collectedCards(player))
            .map(renderCard(false, collectedCards(player)))
        }
        </div>
        <h3 className={styles.sectionHeader}>Cartes à débloquer</h3>
        <div className={styles.viewCards}>
        {cardsList &&
          Object
            .entries(cardsList)
            .sort(cardsByName)
            .filter(unCollectedCards(player))
            .map(renderCard(true, unCollectedCards(player)))
        }
      </div>
    </div>
  );
}

export default Collection;