import { lifecycle } from 'recompose';
import { createEpochGame } from 'services/http';

export default lifecycle({
    async componentDidMount() {
        try {
        const { data: { epochs, firstCard } } = await createEpochGame();
        this.props.initGame(epochs, firstCard);

        } catch (e) {
            console.error(e);
        }
    }
})