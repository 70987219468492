const initialState = {
  openedStartLevelDialog: false,
  lernieChatOpened: false,
  showArena: false,
  showEpochGame: false,
  showEpochRewards: false,
  showRound: false,
  showDebrief: false,
  showCardsRewards: false,
  showSuccesses: false,
  showRegisterForm: false,
  rewards: {},
  successes: {},
};

export default initialState;