import React from 'react';
import styles from './LivesBar.module.scss';

const LivesBar = ({ value }) => (
    <div className={styles.livesBar}>
        <span className={styles.livesBarText}>
            {value}
        </span>
    </div>
);

export default LivesBar;
