import { compose } from 'recompose';

import EpochGame from './EpochGame.design';
import withConnect from './EpochGame.connect';
import withLifeCycle from './EpochGame.lifecycle';
import withAsyncHandlers from './EpochGame.asyncHandlers';
import withStateHandlers from './EpochGame.stateHandlers';

export default compose(
    withConnect,
    withStateHandlers, 
    withAsyncHandlers, 
    withLifeCycle,
)(EpochGame);
