import { takeEvery, put } from 'redux-saga/effects';
import { actions as playerActions } from 'features/player'; 
import { actions as lifecycleActions } from 'features/lifecycle';
import { types as mapTypes, actions as mapActions } from '.';

import {
  getLevelInfos,
  getGameRewards,
  triggerNextLevel
} from 'services/http';

export function* mapLocationFetchSaga({payload: levelId}) {
  try {
    const levelInfosResponse = yield getLevelInfos(levelId);
    yield put(mapActions.mapLocationFetchSuccess(levelInfosResponse.data));
  } catch (error) {
    console.error(error);
    yield put(mapActions.mapLocationFetchError)
  }
}

export function* mapLocationFetchSuccessSaga() {
  yield put(lifecycleActions.lifecycleLernieChatWillStart());
}

export function* fetchCurrentGameRewardsSaga() {
  try {
    const getRewardsResponse = yield getGameRewards();
    yield put(mapActions.fetchCurrentGameRewardsSuccess(getRewardsResponse.data));
  } catch(error) {
    yield put(mapActions.fetchCurrentGameRewardsFail(error));
  }
}

export function* nextLevelSaga() {
  const playerInformationsResponse = yield triggerNextLevel();
  const addedSuccesses = playerInformationsResponse.data.addedSuccesses;
  if (addedSuccesses && Object.keys(addedSuccesses).length > 0){
    yield put(mapActions.showSuccesses(addedSuccesses))
  }
  yield put(mapActions.closeCardsRewards());
}

export function* debriefLevelSaga() {
  yield put(mapActions.showDebrief());
  yield put(mapActions.hideRound());
  yield put(mapActions.startLernieChat());
}

export function* saveAccountSaga() {
  yield put(mapActions.hideRound());
  yield put(mapActions.showAccountCreation());
}

export function* updateMapSaga() {
  yield put(playerActions.fetchPlayerInformationsWithMap())
}

export function* watchMap() {
  yield takeEvery(mapTypes.MAPLOCATION_FETCH, mapLocationFetchSaga);
  yield takeEvery(mapTypes.MAPLOCATION_FETCH_SUCCESS, mapLocationFetchSuccessSaga);
  yield takeEvery(mapTypes.MAP_FETCH_CURRENT_GAME_REWARDS, fetchCurrentGameRewardsSaga);
  yield takeEvery(mapTypes.MAP_NEXT_LEVEL, nextLevelSaga);
  yield takeEvery(mapTypes.MAP_HIDE_CARDS_REWARDS, updateMapSaga);
  yield takeEvery(mapTypes.MAP_DEBRIEF_LEVEL, debriefLevelSaga);
  yield takeEvery(mapTypes.MAP_SAVE_ACCOUNT, saveAccountSaga);
}
