import React from "react";

import styles from "./Game.module.scss";

import {
  OpenedMapDialog,
  LernieChat,
  Arena,
  GameMap,
  CardsRewards,
  SortGameCampaign,
  Successes,
  RegisterForm,
  EpochGame,
  EpochGameRewards
} from 'components';

const Game = ({
  openedStartLevelDialog,
  showDebrief,
  lernieChatOpened,
  showCardsRewards,
  notifyLernieChatEnded,
  showArena,
  showRound,
  showSuccesses,
  showRegisterForm,
  showEpochGame,
  showEpochRewards,
}) => (
  <div className={styles.game}>
    <GameMap />
    {showRound && <SortGameCampaign />}
    {showArena && <Arena />}
    {showEpochGame && <EpochGame />}
    {openedStartLevelDialog && <OpenedMapDialog />}
    {lernieChatOpened && <LernieChat mode={showDebrief ? 'post' : 'pre'} notifyChatEnded={notifyLernieChatEnded}/>}
    {showCardsRewards && <CardsRewards />}
    {showEpochRewards && <EpochGameRewards />}
    {showSuccesses && <Successes />}
    {showRegisterForm && <RegisterForm />}
  </div>
);
  

export default Game;
