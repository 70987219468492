import { connect } from 'react-redux';
import {
    actions as mapActions,
    selectors as mapSelectors,
} from 'features/map';

import {
    selectors as playerSelectors
} from 'features/player';

const mapStateToProps = state => ({
    map: mapSelectors.getCurrentMap(state),
    player: playerSelectors.getPlayerSelector(state),
});

const mapDispatchToProps = {
    closeEpochGame: mapActions.closeEpochGame,
    startLernieChat: mapActions.startLernieChat,
    showDebrief: mapActions.showDebrief,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;