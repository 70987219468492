import { compose, lifecycle } from 'recompose';

import ViewCards from './ViewCards.design';
import ViewCardsLifeCycle from './ViewCards.lifecycle';
import withConnect from './ViewCards.connect';

export default compose(
    withConnect,
    lifecycle(ViewCardsLifeCycle),
)(ViewCards);

