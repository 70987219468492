import App from './App.design';

import { compose } from 'recompose';

import withConnect from './App.connect';
import appLifeCycle from './App.lifecycle';


export default compose(
    withConnect,
    appLifeCycle,
)(App);
