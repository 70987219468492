import React from 'react';

import { SortGameBoard, Chronometer, CheckButton, LivesBar, WonRound, WrongOrder, LostRound, EnergyBar, StrengthBar, SmallModal } from 'components';

import styles from './SortGameCommon.module.scss';

const SortGameCommon = (props) => {
  const {
  cardsDetails,
  
  disableXp,
  disableLives,
  disableStrength,
  disableEnergy,
  disableTime,
  levelHint,

  playerLevel,
  energy,
  nbLives,

  gameStrength,

  moveCardsFromTo,
  handleOutOfTime,
  checkCardsOrder,
  campaignGameInit,

  isOutOfEnergy,
  isOutOfTime,
} = props;
  return (
    <div className={styles.appBoard}>
      <div className={styles.barsContainer}>
        {!disableXp && (
          <div className={styles.avatarBar}>
            <div className={styles.avatarOuter}><div className={styles.avatar}></div></div>
            <div className={styles.avatarLevel}>{playerLevel}</div>
          </div>
        )}
        {!disableLives && (
          <LivesBar value={nbLives} />
        )}
        {!disableStrength && (
          <StrengthBar value={gameStrength} />
        )}
        {!disableEnergy && (
          <EnergyBar value={energy} />
        )}
        <div style={{ margin: 'auto' }}></div>
        {!disableTime && (<Chronometer handleOutOfTime={handleOutOfTime} />)}
      </div>
      <div className={styles.mainArea}>
        {levelHint && <p className={styles.levelHint}>{levelHint}</p>}
        <div className={styles.cardBoard}>
          <SortGameBoard
            cardsDetails={cardsDetails}
            moveCardsFromTo={moveCardsFromTo} />
        </div>
        <div className={styles.checkButtonWrapper}>
          <CheckButton checkCardsOrder={() => checkCardsOrder(cardsDetails)} />
        </div>
      </div>
      {!disableLives && <LostRound />}
      <WonRound />
      <WrongOrder />
      {!disableEnergy && isOutOfEnergy && <SmallModal
        onClick={campaignGameInit}
        text="Plus d'énergie"
        buttonCaption="Récompenses"
      />}
      {!disableTime && isOutOfTime && <SmallModal
        onClick={campaignGameInit}
        text="Plus de temps"
        buttonCaption="Récompenses"
      />}
    </div>
  )
};

SortGameCommon.defaultProps = {
  cardsDetails: [],
  
  disableXp: true,
  disableLives: true,
  disableStrength: true,
  disableEnergy: true,
  disableTime: true,
  levelHint: '',

  playerLevel: 1,
  energy: 3,
  nbLives: 3,

  gameStrength: 1,

  moveCardsFromTo: () => {},
  handleOutOfTime: () => {},
  checkCardsOrder: () => {},
  campaignGameInit: () => {},

  isOutOfEnergy: false,
  isOutOfTime: false,
}

export default SortGameCommon;
