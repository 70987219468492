import {
  CARDS_LIST_FETCH,
  CARDS_LIST_FETCH_SUCCESS,
  CARDS_LIST_FETCH_FAILURE,
} from './types';

export const fetchCardsList = () => ({
  type: CARDS_LIST_FETCH,
});

export const fetchCardsListSuccess = (data) => ({
  type: CARDS_LIST_FETCH_SUCCESS,
  payload: data,
});

export const fetchCardsListFailure = (error) => ({
  type: CARDS_LIST_FETCH_FAILURE,
  payload: error,
});
