import { compose } from 'recompose';

import SortGameCommon from './SortGameCommon.design';
import withLifeCycle from './SortGameCommon.lifecycle';
import withAsyncHandlers from './SortGameCommon.asyncHandlers';
import withStateHandlers from './SortGameCommon.stateHandlers';
import mapProps from './SortGameCommon.mapProps';

export default compose(
    withStateHandlers, 
    withAsyncHandlers, 
    withLifeCycle,
    mapProps,
)(SortGameCommon);
