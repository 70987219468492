import { withStateHandlers } from 'recompose';

import { NB_MAX_ENERGY } from 'configuration/parameters';
import { arrayMove } from 'services/arrays';
import { moveCard as serverMoveCard } from 'services/http';
const initialState = (props) => {
  console.log(props);
  return (
    {
      cardsDetails: props.cardsDetails,
      numberOfAttempts: 0,
      numberOfMoves: 0,
      uuid: '',
      startTimeStamp: 0,
      gameStarted: false,
    });
}

const handlers = {
  moveCardsFromTo: (state, props) => (from, to) => {
    const { map, handleOutOfEnergy } = props;
    try {
      serverMoveCard(from, to);

      const nbMaxEnergy = map.maxEnergy ? map.maxEnergy : NB_MAX_ENERGY;
      if (!map.disableEnergy && state.numberOfMoves >= nbMaxEnergy) {
        handleOutOfEnergy();
      }

      return {
        cardsDetails: arrayMove(state.cardsDetails, from, to),
        numberOfMoves: state.numberOfMoves + 1,
      };
    } catch (e) {
      console.error(e);
    }
  },
  updateStateHandlerProps: () => updatedState => ({ ...updatedState }),
}

export default withStateHandlers(
  initialState,
  handlers,
);
