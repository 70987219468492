import { lifecycle } from 'recompose';

const ChronometerLifeCycle = {
    componentDidMount() {
        const { timerId, updateValue } = this.props;
        this.props.startTimer({ timerId }, { updateValue })
    },

    componentWillUnmount() {
        const { timerId } = this.props;
        clearInterval(timerId);
    }
}

export default lifecycle(ChronometerLifeCycle);