import React from 'react';

import { Chronometer } from 'components';

import styles from './EpochGameFooter.module.scss';

const EpochGameFooter = ({
  endGame,
  scoresList,
  score,
}) => (
  <div className={styles.bottomArea}>
    <Chronometer outOfTime={endGame} />
    <div className={styles.scoreArea} id="scoreArea">
      <p className={styles.score}>Score: {score}</p>
      {scoresList.map((score, index) => (
        <span className={styles[score.type]} key={index}>
        + {score.value}
        </span>
      ))}
    </div>
  </div>
);

export default EpochGameFooter;