import { connect } from 'react-redux';
import {
  actions as playerActions,
  selectors as playerSelectors,
} from 'features/player';
import {
  actions as cardsListActions,
  selectors as cardsListSelectors,
} from 'features/cardsList';

const mapStateToProps = state => ({
  player: playerSelectors.getPlayerSelector(state),
  cardsList: cardsListSelectors.getAllCards(state),
});

const mapDispatchToProps = {
  fetchPlayerInformationsWithMap: playerActions.fetchPlayerInformationsWithMap,
  fetchCardsList: cardsListActions.fetchCardsList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;