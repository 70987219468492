import React from 'react';
import L from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet'

import styles from './GameMapIcon.module.scss';
import './leaflet-tooltip-pane.module.scss';

import iconDefinitions from './GameMapIcon.definitions';

const instanciateIcon = icon => ({
    key: icon.iconPath,
    leafletIcon: new L.Icon({
        // eslint-disable-next-line
        iconUrl: require(`./assets/${icon.iconPath}.png`),
        iconSize: new L.Point(icon.iconSize[0], icon.iconSize[1]),
        className: styles[icon.className],
    })
})

const iconArrayToHashtable = (iconsList, currentIcon) => ({
    ...iconsList,
    [currentIcon.key]: currentIcon.leafletIcon
});
const icons = iconDefinitions
    .map(instanciateIcon)
    .reduce(iconArrayToHashtable, {});
    
const getIconForLevel = ({levelId, iconType, player, isUnlocked}) => {
    const iconName = !isUnlocked ? `${iconType}-off` : iconType;
    if (!icons[iconName]) {
        console.log(`icon not found: ${iconName}`);
        return 'default';
    }
    return iconName;
}

export default ({ levelId, levelName, levelNumber, mapPosition, iconType, player, isUnlocked, handleMarkerClick }) => {
    const iconName = getIconForLevel({levelId, iconType, player, isUnlocked});
    return (
        <Marker
            key={levelId}
            position={mapPosition}
            icon={icons[iconName]}
            onClick={() => handleMarkerClick(levelId)}
        >
            <Tooltip
                className={styles[iconName]}
                direction="bottom"
                permanent
            >
                <span>
                    {levelName ? levelName : '???'}
                </span>
            </Tooltip>
        </Marker>
    );
}