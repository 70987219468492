import { compose, lifecycle } from 'recompose';

import Collection from './Collection.design';
import CollectionLifeCycle from './Collection.lifecycle';
import withConnect from './Collection.connect';
import { withRouter } from 'react-router-dom';

export default compose(
  withRouter,
  withConnect,
  lifecycle(CollectionLifeCycle),
)(Collection);