import { withHandlers } from 'recompose';

const handlers = {
    nextStepAsyncHandler: ({nextStep}) => (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        nextStep();
    },
};

export default withHandlers(
    handlers,
);
