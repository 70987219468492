import { lifecycle } from 'recompose';

export default lifecycle({
  componentDidMount() {
    try {
      this.props.setNewGame({
        cardsDetails: this.props.cardsDetails,
        numberOfAttempts: this.props.numberOfAttempts,
        numberOfMoves: 0,
        startTimeStamp: this.props.startTimeStamp,
        uuid: this.props.uuid,
      });
    } catch (e) {
      console.error(e);
    }
  },

});
