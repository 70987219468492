
import { 
    LIFECYCLE_LERNIE_ALTERNATIVE_CHAT,
    LIFECYCLE_LERNIE_CHAT_WILL_START,
    LIFECYCLE_GAME_WILL_START,
    LIFECYCLE_LERNIE_ENDED_SPEAKING,
    LIFECYCLE_LERNIE_ENDED_DEBRIEF,
} from './types';

export const lifecycleLernieChatWillStart = () => ({
    type: LIFECYCLE_LERNIE_CHAT_WILL_START,
});

export const lifecycleLernieEndedSpeaking = () => ({
  type: LIFECYCLE_LERNIE_ENDED_SPEAKING,
});

export const lifecycleLernieEndedDebrief= () => ({
  type: LIFECYCLE_LERNIE_ENDED_DEBRIEF,
});

export const lifecycleAlternativeLernieChat = alternativeLernieChatParams => ({
  type: LIFECYCLE_LERNIE_ALTERNATIVE_CHAT,
  payload: alternativeLernieChatParams,
});

export const lifecycleGameWillStart = () => ({
    type: LIFECYCLE_GAME_WILL_START,
});
