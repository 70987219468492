import { withHandlers } from 'recompose';

import { moveEpochCard} from 'services/http';

const handlers = {
    dropCard: (props) => async (droppableId) => {
        const droppedCategory = props.epochs[droppableId];
        if (droppedCategory) {
            if (props.currentCard.date >= droppedCategory.startDate && props.currentCard.date <= droppedCategory.endDate) {
                const newCard = await moveEpochCard(droppableId);
                props.updateEpochCard(newCard.data.nextCard);
                props.displayOk(droppableId);
            } else {
                await moveEpochCard(droppableId);
                props.displayMissed(droppableId);
            }
        }
    }
};

export default withHandlers(handlers);
