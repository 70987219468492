import { compose } from 'recompose';

import Success from './RegisterForm.design';
import withConnect from './RegisterForm.connect';
import withStateHandlers from './RegisterForm.stateHandlers';
import withAsyncHandlers from './RegisterForm.asyncHandlers';
import withLifecycle from './RegisterForm.lifecycle';

export default compose(
    withConnect,
    withStateHandlers,
    withAsyncHandlers,
    withLifecycle,
)(Success);

