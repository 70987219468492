import { withStateHandlers } from 'recompose';

const initialState = {
    preloadedImages: false,
}

const handlers = {
    setImagesLoaded: () => () => ({
        preloadedImages: true,
    })
}

export default withStateHandlers(
    initialState,
    handlers,
);