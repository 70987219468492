import { takeEvery, put } from 'redux-saga/effects';
import { getAllCards } from 'services/http';
import * as actions from './actions';
import * as types from './types';

export function* fetchCardsListSaga({ payload: areaId }) {
  try {
    const cardsResponse = yield getAllCards();

    yield put(actions.fetchCardsListSuccess(cardsResponse.data));
  } catch (error) {
    yield put(actions.fetchCardsListFailure(error));
  }
}

export function* watchCardsList() {
  yield takeEvery(types.CARDS_LIST_FETCH, fetchCardsListSaga);
}
