import React from 'react';

import styles from './WonRound.module.scss';

import { NB_MAX_LIVES, NB_MAX_ENERGY } from '../../configuration/parameters';
import gameStrengths from '../../configuration/gameStrengths';

const timeElapsedSince = roundStartTime => new Date().getTime() - roundStartTime;
const scoreCalculation = (
  roundStartTime,
  numberOfAttempts,
  playerGameStrength,
  numberOfMoves,
) => Math.max(0, Math.floor(
  (
    200000 - Math.min(200000, timeElapsedSince(roundStartTime))
  ) / 6000 / (numberOfAttempts + 1)
  * playerGameStrength * 3
  - 2 * numberOfMoves)
) * ((numberOfAttempts === 1) ? 2 : 1);

const xpEarned = (
  roundStartTime,
  numberOfAttempts,
  playerGameStrength
) => Math.max(0, Math.floor(
  (
    200000 - Math.min(200000, timeElapsedSince(roundStartTime))
  ) / 1325 / (numberOfAttempts + 3)
  * playerGameStrength * 13)
);

const previousGameStrength = level => gameStrengths[gameStrengths[level].previousLevel];
const currentGameStrength = level => gameStrengths[level];

const hasNextLevelMaskedDates = (playerGameStrength) => {
  if (previousGameStrength(playerGameStrength).maskedDates === false && currentGameStrength(playerGameStrength).maskedDates === true) {
    return true;
  }
  return false;
};
const hasNextLevelMoreCards = (playerGameStrength) => {
  if (previousGameStrength(playerGameStrength).nbCards < currentGameStrength(playerGameStrength).nbCards) {
    return true;
  }
  return false;
}

const WonRound = ({ round, debriefLevel, player, map }) => {
  const customWonMessage = map?.customWonMessage;
  const nbMaxEnergy = map.maxEnergy ? map.maxEnergy : NB_MAX_ENERGY;
  return round.areCardsOrdered ? (
    <div className={styles.wonRoundWrapper}>

      <div className={styles.wonRound}>
        <p className={styles.wonRoundText}>BRAVO !</p>
        <p className={styles.wonRoundDetails}>
          {customWonMessage
            ? customWonMessage
            : (<ul className={styles.wonRoundListWrapper}>
              <li><span className={styles.indicator}><i className={styles.strIcon}>&nbsp;</i>{player.gameStrength}</span> Pallier de difficulté suivant</li>
              <li><span className={styles.indicator}><i className={styles.energyIcon}>&nbsp;</i>{nbMaxEnergy}</span> On refait le plein d'énergie !</li>
              {hasNextLevelMoreCards(player.gameStrength) && <li><span className={styles.indicator}><i className={styles.cardIcon}>&nbsp;</i>{currentGameStrength(player.gameStrength).nbCards}</span> Une carte de plus ! ({previousGameStrength(player.gameStrength).nbCards}->{currentGameStrength(player.gameStrength).nbCards})</li>}
              {hasNextLevelMaskedDates(player.gameStrength) && <li><span className={styles.indicator}><i className={styles.noDateIcon}>&nbsp;</i></span> Les dates deviennent masquées sauf pendant la vérification !</li>}
              <li><span className={styles.indicator}>(sco)</span> Score total : ******* (+{scoreCalculation(round.startTime.getTime(), round.numberOfAttempts, player.gameStrength, round.numberOfMoves)})</li>
              <li><span className={styles.indicator}>(exp)</span> Points d'expérience gagnés: {xpEarned(round.startTime.getTime(), round.numberOfAttempts, player.gameStrength)}</li>
              <li><span className={styles.indicator}>(car)</span> Progression prochaine carte : **%</li>
              <li><span className={styles.indicator}><i className={styles.heartIcon}>&nbsp;</i>{NB_MAX_LIVES - round.numberOfAttempts}</span> Il te reste {NB_MAX_LIVES - round.numberOfAttempts} vies</li>
            </ul>)}
        </p>
        <div className={styles.wonRoundSeparator} />
        <div className={styles.bottomBar}>
          <div className={styles.wonRoundNextButton} onClick={debriefLevel}><p>Suivant!</p></div>
        </div>
      </div>
    </div>
  ) : (
      <div></div>
    );
}
export default WonRound;
