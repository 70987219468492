import React from 'react';

import styles from './App.module.scss'

import './App.scss';

import renderRoutes from 'routes';

const App = () => (
  <div className={styles.appBoard}>
    {renderRoutes()}
  </div>);

export default App;