import { connect } from 'react-redux';

import { 
    selectors as mapSelectors,
} from 'features/map';
import { selectors as playerSelectors } from 'features/player';
import {
  actions as roundActions,
  selectors as roundSelectors,
} from 'features/round';

const mapStateToProps = state => ({
    map: mapSelectors.getCurrentMap(state),
    player: playerSelectors.getPlayerSelector(state),
    round: roundSelectors.getRoundParams(state),
});

const mapDispatchToProps = { closeWrongOrderModal: roundActions.closeWrongOrderModal };

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;
