import React from 'react';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { EpochGameHeader, EpochGameEndGameModal, EpochGameFooter, EpochGameCategory, EpochGameDistributor } from 'components';

import styles from './EpochGame.module.scss';

const byCategoryStartDate = (cat1, cat2) => cat1[1].startDate - cat2[1].startDate;

const displayEpochCategories = (dropCard, checkedCategoryId, foundAnswer) => ([categoryId, category]) => (<EpochGameCategory
  onClick={dropCard}
  key={categoryId}
  id={categoryId}
  category={category}
  isOk={checkedCategoryId === categoryId && foundAnswer}
  isWrong={checkedCategoryId === categoryId && !foundAnswer} />);

const droppableEpoch = (currentCard, foundAnswer, areDatesBlurred) => provided => (
  <div
    ref={provided.innerRef}
    className={styles.distributorCardArea}
  >
    <EpochGameDistributor card={{ ...currentCard, areDatesBlurred: foundAnswer && areDatesBlurred }} />
    {provided.placeholder}
  </div>
);

const EpochGame = ({
  areDatesBlurred,
  checkedCategoryId,
  closeEpochGame,
  currentCard,
  endGame,
  epochs,
  dropCard,
  foundAnswer,
  lives,
  showDebrief,
  showEndGameModal,
  startLernieChat,
  strength,
  scoresList,
  score,
}) => (
    <div className={styles.epochGameBoard}>
      {
        showEndGameModal &&
        <EpochGameEndGameModal
          closeEpochGame={closeEpochGame}
          showDebrief={showDebrief}
          startLernieChat={startLernieChat}
          score={score}
        />
      }
      {
        epochs && <DragDropContext onDragEnd={e => dropCard(e?.destination?.droppableId)}>
          <div
            className={styles.distributorArea}
          >
            <EpochGameHeader lives={lives} strength={strength}/>
            <div className={styles.topDescription}></div>
            <Droppable
              droppableId="distributorArea"
              direction="horizontal"
            >
              {droppableEpoch(currentCard, foundAnswer, areDatesBlurred)}
            </Droppable>
            <div className={styles.bottomDescription}>{currentCard.description}</div>
            <EpochGameFooter
              endGame={endGame}
              score={score}
              scoresList={scoresList}
            />
          </div>
          <div className={Object.keys(epochs).length % 2 === 0 ?
            styles.categoriesAreaTwoCols :
            styles.categoriesArea}>
            {Object
              .entries(epochs)
              .sort(byCategoryStartDate)
              .map(displayEpochCategories(dropCard, checkedCategoryId, foundAnswer))}
          </div>
        </DragDropContext>
      }
    </div>
  );

export default EpochGame;
