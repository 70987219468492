import t from 'prop-types';

import { compose } from 'recompose';

import LernieChat from './LernieChat.design';
import withConnect from './LernieChat.connect';
import withStateHandlers from './LernieChat.stateHandlers';
import withLifeCycle from './LernieChat.lifecycle';
import { DISCUSSION_MODES } from './LernieChat.constants';

const EnhancedLernieChat = compose(
    withConnect,
    withStateHandlers,
    withLifeCycle,
)(LernieChat);

EnhancedLernieChat.defaultProps = {
    mode: 'pre',
    notifyChatEnded: () => console.log('Chat ended ! do something here !'),
};

EnhancedLernieChat.propTypes = {
    mode: t.oneOf(DISCUSSION_MODES),
    notifyChatEnded: t.func,
}

export default EnhancedLernieChat;