import {
  INCREASE_DIFFICULTY_LEVEL,
  PLAYER_FETCH_FAILURE, PLAYER_FETCH_SUCCESS,
  PLAYER_FETCH} from './types';

export const increaseGameStrength = (gameStrength) => ({
  type: INCREASE_DIFFICULTY_LEVEL,
  payload: gameStrength
})

export const fetchPlayerInformationsWithMap = () => ({
  type: PLAYER_FETCH,
});

export const playerFetchSuccess = playerInformations => ({
  type: PLAYER_FETCH_SUCCESS,
  payload: playerInformations,
});

export const fetchPlayerFailure = (error) => ({
  type: PLAYER_FETCH_FAILURE,
  error,
});