import { createReducer } from '@reduxjs/toolkit';

import { types } from '.';

import initialState from './initialState';

import { types as campaignGameTypes } from 'features/campaignGame';


const mapReducer = createReducer(initialState, {
  [types.MAPLOCATION_FETCH_SUCCESS]: (_, action) => ({ ...action.payload }),
  [types.MAP_OPEN_START_LEVEL_DIALOG]: (state, action) => ({ ...state, ...action.payload, openedStartLevelDialog: true }),
  [types.MAP_CLOSE_START_DIALOG]: state => ({ ...state, openedStartLevelDialog: false, lernieChatOpened: false }),
  [types.MAP_MAIN_GAME]: state => ({ ...state, showRound: true }),
  [types.MAP_HIDE_ROUND]: state => ({ ...state, showRound: false }),
  [types.MAP_SHOW_DEBRIEF]: state => ({ ...state, showDebrief: true }),
  [types.MAP_SHOW_CARDS_REWARDS]: state => ({ ...state, showCardsRewards: true }),
  [types.MAP_HIDE_CARDS_REWARDS]: state => ({ ...state, showCardsRewards: false, showDebrief: false }),
  [types.MAP_FETCH_CURRENT_GAME_REWARDS_SUCCESS]: (state, action) => ({ ...state, rewards: action.payload }),
  [types.MAP_SHOW_SUCCESSES]: (state, action) => ({ ...state, successes: action.payload, showSuccesses: true }),
  [types.MAP_HIDE_SUCCESSES]: state => ({ ...state, successes: {}, showSuccesses: false}),
  [types.MAP_SHOW_ACCOUNT_CREATION]: state => ({ ...state, showRegisterForm: true }),
  [types.MAP_HIDE_REGISTER]: state => ({...state, showRegisterForm: false }),
  [types.MAP_UPDATE_PRE_TALK]: (state, action) => ({...state, preDiscussion: action.payload }),

  [types.MAP_OPEN_EPOCH_GAME]: state => ({...state, showEpochGame: true }),
  [types.MAP_CLOSE_EPOCH_GAME]: state => ({...state, showEpochGame: false}),
  [types.MAP_OPEN_EPOCH_REWARDS]: state => ({...state, showEpochRewards: true }),
  [types.MAP_CLOSE_EPOCH_REWARDS]: state => ({...state, showEpochRewards: false}),

  [types.LERNIE_START_CHAT]: state => ({ ...state, lernieChatOpened: true }),
  [types.LERNIE_STOP_CHAT]: state => ({ ...state, lernieChatOpened: false }),
  
  [campaignGameTypes.CAMPAIGN_GAME_INIT]: state => ({ ...state, showRound: false }),
});

export default mapReducer;
