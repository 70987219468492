import React from 'react';

import { Game } from 'components';
import styles from './Game.module.scss';

const GamePage = () => (
    <div className={styles.menuWrapper}>
       <Game />
    </div>
);

export default GamePage;
