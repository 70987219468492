import React from 'react';

import { StaticCard } from 'components';

import styles from './ViewCards.module.scss';

export default ({ cardsList }) => (
    <>
        <h2 className={styles.collectionHeader}>Ma collection</h2>
    <div className={styles.viewCards}>
        {cardsList && cardsList.map((card, index) => 
        <div className={styles.cardWrapper}><StaticCard
            id={card._id}
            index={index}
            name={card.name}
            image={card.illustration}
            date={card.date}
            dateLabel={card.dateLabel}
            description={card.description}
            value={card.rarity}
            theme={card.theme}>

        </StaticCard></div>)}
    </div>
    </>
);
