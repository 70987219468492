
export const MAPLOCATION_FETCH = 'timewreck/mapLocation/FETCH';
export const MAPLOCATION_FETCH_SUCCESS = 'timewreck/mapLocation/FETCH.SUCCESS';
export const MAPLOCATION_FETCH_ERROR = 'timewreck/mapLocation/FETCH.ERROR';

export const MAP_OPEN_START_LEVEL_DIALOG = 'timewreck/dialog/START_GAME.OPEN';
export const MAP_CLOSE_START_DIALOG = 'timewreck/dialog/START_GAME.CLOSE';

export const LERNIE_START_CHAT = 'timewreck/lernie/START_CHAT';
export const LERNIE_STOP_CHAT = 'timewreck/lernie/STOP_CHAT';

export const MAP_START_GAME = 'timewreck/map/START_GAME';
export const MAP_MAIN_GAME = 'timewreck/map/MAIN_GAME';
export const MAP_DEBRIEF_LEVEL = 'timewreck/map/DEBRIEF_LEVEL';

export const MAP_SHOW_CARDS_REWARDS = 'timewreck/map/SHOW_CARDS_REWARDS';
export const MAP_HIDE_CARDS_REWARDS = 'timewreck/map/HIDE_CARDS_REWARDS';

export const MAP_FETCH_CURRENT_GAME_REWARDS = 'timewreck/map/FETCH_CURRENT_GAME_REWARDS';
export const MAP_FETCH_CURRENT_GAME_REWARDS_SUCCESS = 'timewreck/map/FETCH_CURRENT_GAME_REWARDS.SUCCESS';
export const MAP_FETCH_CURRENT_GAME_REWARDS_FAIL = 'timewreck/map/FETCH_CURRENT_GAME_REWARDS.FAIL';

export const MAP_NEXT_LEVEL = 'timewreck/map/NEXT_LEVEL';

export const MAP_SHOW_SUCCESSES = 'timewreck/map/SHOW_SUCCESSES';
export const MAP_HIDE_SUCCESSES = 'timewreck/map/HIDE_SUCCESSES';

export const MAP_HIDE_REGISTER = 'timewreck/map/HIDE_REGISTER';

export const MAP_HIDE_ROUND = 'timewreck/map/HIDE_ROUND';
export const MAP_SHOW_DEBRIEF = 'timewreck/map/SHOW_DEBRIEF';

export const MAP_SAVE_ACCOUNT= 'timewreck/map/SAVE_ACCOUNT';
export const MAP_SHOW_ACCOUNT_CREATION = 'timewreck/map/SHOW_ACCOUNT_CREATION';

// Life cycles 
export const LIFECYCLE_LERNIE_ENDED_SPEAKING = 'timewreck/lifecycle/LERNIE_ENDED_SPEAKING';

// Life cycles actions
export const LIFECYCLE_LERNIE_ALTERNATIVE_CHAT = 'timewreck/lifecycle/ALTERNATIVE_LERNIE_CHAT';

export const MAP_UPDATE_PRE_TALK = 'timewreck/map/UPDATE_PRE_TALK';

// Arena
export const MAP_OPEN_ARENA = 'timewreck/map/OPEN_ARENA';
export const MAP_CLOSE_ARENA = 'timewreck/map/CLOSE_ARENA';

// Epoch game
export const MAP_OPEN_EPOCH_GAME = 'timewreck/map/OPEN_EPOCH_GAME';
export const MAP_CLOSE_EPOCH_GAME = 'timewreck/map/CLOSE_EPOCH_GAME';
export const MAP_OPEN_EPOCH_REWARDS = 'timewreck/map/OPEN_EPOCH_REWARDS';
export const MAP_CLOSE_EPOCH_REWARDS = 'timewreck/map/CLOSE_EPOCH_REWARDS';
