import { compose } from 'recompose';

import CardBoard from './SortGameBoard.design';
import withConnect from './SortGameBoard.connect';
import withProps from './SortGameBoard.props';

export default compose(
    withConnect,
    withProps,
)(CardBoard);
