import { connect } from 'react-redux';
import {
    actions as mapActions,
    selectors as mapSelectors,
} from 'features/map';
import {
    actions as playerActions,
    selectors as playerSelectors
} from 'features/player';

const mapStateToProps = (state, props) => ({
    player: playerSelectors.getPlayerSelector(state),
    currentPlayerLocation: playerSelectors.getCurrentPlayerLocation(state, props),
    hasPlayerUnlockedSavedGames: playerSelectors.hasPlayerUnlockedSavedGames(state),
    openedStartLevelDialog: mapSelectors.isOpenedStartLevelDialog(state),
});

const mapDispatchToProps = {
    fetchPlayerInformationsWithMap: playerActions.fetchPlayerInformationsWithMap,
    mapLocationFetch: mapActions.mapLocationFetch,
    saveAccount: mapActions.saveAccount,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false });
