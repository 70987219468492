import { connect } from 'react-redux';
import { actions as playerActions } from 'features/player';
import { actions as cardsListActions } from 'features/cardsList';

const mapStateToProps = ({ player, cardsList }) => ({ player, cardsList });

const mapDispatchToProps = {
  fetchPlayerInformationsWithMap: playerActions.fetchPlayerInformationsWithMap,
  getAllCards: cardsListActions.fetchCardsList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;