import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { compose } from 'recompose'

import { composeWithDevTools } from 'redux-devtools-extension';
import { createRootReducer, rootSaga } from './features';

const sagaMiddleware = createSagaMiddleware();

let store = null;

export const history = createBrowserHistory();

if (!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {

  store = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware)),
  );
} else {
  store = createStore(
    createRootReducer(history),
    composeWithDevTools(compose(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware)
      )
    ),
  );
}

sagaMiddleware.run(rootSaga);

export default store;
