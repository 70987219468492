import { connect } from 'react-redux';
import {
    selectors as playerSelectors
} from 'features/player';

const mapStateToProps = state => ({
  player: playerSelectors.getPlayerSelector(state),
});

const withConnect = connect(mapStateToProps, null, null, { pure: false });

export default withConnect;
