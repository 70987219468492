import React from 'react';

import styles from './RegisterForm.module.scss';

const eventStopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
}

const stepsDesign = [
    ({step}) => (<div className={styles.registerFormDialog}>
        <p className={styles.registerFormTitle}>Sauvegarder ta progression</p>
        <p className={styles.registerFormParagraph}>Tu vas pouvoir rejouer par la suite d'où tu veux et sauvegarder les niveaux que tu auras terminé, ainsi que les cartes que tu auras gagné !</p>
        <p className={styles.registerFormParagraph}>Tu peux choisir le bouton vert pour commencer la sauvegarde ou le bouton rouge pour annuler. Pas de panique, tu pourras t'inscrire quand tu veux, si tu ne veux pas le faire maintenant.</p>
    </div>),
    (props) => {
        return (<div className={styles.registerFormDialog}>
        <p className={styles.registerFormTitle}>Choisis un nom !</p>
        <p className={styles.registerFormFormParagraph}>La première étape va consister à te trouver un p'tit nom sympa, tu auras l'occasion de le partager plus tard avec des copains ou des copines...</p>
        <form
            className={styles.form}
            onSubmit={
                goToNextStep(props) ?
                props.nextStepAsyncHandler :
                eventStopPropagation
            } >
            <p className={styles.formInner}>
                <span className={styles.label}>Ton nom: </span>
                <input
                    id="nameRegisterForm"
                    className={styles.inputField}
                    type="text"
                    name="name"
                    value={props.name}
                    onChange={props.setName}
                />
            </p>
        </form>
        <p className={styles.errorMessage}>{props.nameAlreadyTaken && 'Nom déjà pris, désolé !'}</p>
    </div>)
    },
    (props) => (<div className={styles.registerFormDialog}>
        <p className={styles.registerFormTitle}>Rentre ton courriel !</p>
        <p className={styles.registerFormFormParagraph}>Pour m'assurer que c'est bien toi à chaque fois et que quelqu'un d'autre ne te pique pas ton compte, j'aurai besoin que tu me donnes ton courriel, ou a défaut celui de tes parents...</p>
        <p className={styles.registerFormFormParagraph}>Demande leur la permission quand même !</p>
        <form className={styles.form} onSubmit={e => goToNextStep(props) ? props.nextStepAsyncHandler(e) : eventStopPropagation(e) }>
            <p className={styles.formInner}>
                <span className={styles.label}>Ton courriel: </span>
                <input
                    id="emailRegisterForm"
                    className={styles.inputField}
                    type="text"
                    name="email"
                    value={props.email}
                    onChange={props.setEmail}
                />
            </p>
        </form>
        <p className={styles.errorMessage}>{props.emailAlreadyRegistered && 'Courriel déjà enregistré, désolé !'}</p>
    </div>),
    ({ step }) => (<div className={styles.registerFormDialog}>
        <p className={styles.registerFormTitle}>Attention ici !</p>
        <p className={styles.registerFormFormParagraph}>Tu auras certainement besoin de l'aide de tes parents une dernière fois, et après ils peuvent te laisser jouer tranquillement...</p>
        <p className={styles.registerFormFormParagraph}>Il va falloir que tu te crées un mot de passe dont tu te souviennes et que tu ne donnes à personne. Celui-ci doit contenir au moins 8 caractères, dont au moins une minuscule, au moins une majuscule et au moins un chiffre...</p>
        <p className={styles.registerFormFormParagraph}>Prêt pour rentrer ton mot de passe ?</p>
    </div>),
    (props) => (<div className={styles.registerFormDialog}>
        <p className={styles.registerFormTitle}>Le mot de passe !</p>
        <p className={styles.registerFormFormParagraph}>Pour être sur qu'on ne fait pas de bêtises, je vais te demander de le rentrer deux fois !</p>
        <p className={styles.registerFormFormParagraph}>Si jamais tu le perds, pas de panique, on s'en créera un nouveau grace à ton courriel.</p>
        <form className={styles.form} onSubmit={e => goToNextStep(props) ? props.nextStepAsyncHandler(e) : eventStopPropagation(e)}>
            <p className={styles.formInner}>
                <span className={styles.label}>Première fois : </span>
                <input
                    id="password1RegisterForm"
                    className={styles.inputField}
                    type="password"
                    name="password1"
                    value={props.password1}
                    onChange={props.setPassword1}
                />
                <span className={styles.label}>Seconde fois : </span>
                <input className={styles.inputField} type="password" name="password2" value={props.password2} onChange={props.setPassword2}/>
            </p>
        </form>
    </div>),
    (props) => (<div className={styles.registerFormDialog}>
        <p className={styles.registerFormTitle}>Merci !</p>
        <p className={styles.registerFormFormParagraph}>C'est sympa de t'être inscrit ! Grace à ton inscription, tu vas pouvoir collectionner plein de super cartes et montrer ta collection à tes amis.</p>
        <p className={styles.registerFormFormParagraph}>Tu verras on va bien s'amuser et apprendre plein de choses du passé !</p>
        <p className={styles.registerFormFormParagraphEmphasis}>Bienvenue dans Time-Wreck, <strong>{props.name}</strong> !</p>
    </div>),
]

const classNameNextButton = props => {
    if (props.step === stepsDesign.length - 1) {
        return styles.okButton;
    }
    if (props.step === 1 && (props.nameTooShort || props.nameAlreadyTaken)) {
        return styles.nextButtonDisabled;
    }

    if (props.step === 2 && (!props.isValidEmail || props.emailAlreadyRegistered)) {
        return styles.nextButtonDisabled;
    }
    if (props.step === 4 &&
        (
            !props.isValidPassword1
            || !props.isValidPassword2
            || !(props.password1 === props.password2)
        )
    ) {
        return styles.nextButtonDisabled;
    }
    return styles.nextButton;
}

const classNamePreviousButton = props => {
    if (props.step === 0) {
        return styles.previousButtonDisabled;
    }
    if (props.step === 5) {
        return styles.previousButtonDisabled;
    }
    return styles.previousButton;
}

const goToNextStep = props => {
    if (props.step === 1 && (props.nameTooShort || props.nameAlreadyTaken)) {
        return false;
    }
    if (props.step === 2 && (!props.isValidEmail || props.emailAlreadyRegistered)) {
        return false;
    }
    if (
        props.step === 4 &&
        (
            !props.isValidPassword1
            || !props.isValidPassword2
            || !(props.password1 === props.password2)
        )
    ) {
        return false;
    }
    return true;
}

const goToPreviousStep = props => {
    if (props.step === 0) {
        return false;
    }
    if (props.step === 5) {
        return false;
    }
    return true;
}

const RegisterForm = (props) => {
    const { hideRegister, step, nextStepAsyncHandler, previousStep } = props;
    return (
        <div className={styles.successWrapper}>
            <div className={styles.success}>
                {stepsDesign[step] && stepsDesign[step](props)}
                <div className={styles.registerSeparator} />
                <div className={styles.bottomBar}>
                    <div className={styles.laterButton} onClick={hideRegister}> </div>
                    <div className={classNamePreviousButton(props)} onClick={e => { goToPreviousStep(props) && previousStep(e)}}> </div>
                    <div
                        className={classNameNextButton(props)}
                        onClick={e => {
                            goToNextStep(props) && nextStepAsyncHandler(e)
                        }}
                    > </div>
                </div>
            </div>
        </div>
    );
}
export default RegisterForm;