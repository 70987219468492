
import React from 'react';

import styles from './LernieChat.module.scss';

const KokkiBubble = ({ text }) => (
    <div className={styles.kokkiBubbleWrapper}>
        <p className={styles.kokkiBubble}>
            {text}
        </p>
    </div>);

const ChrysBubble = ({ text }) => (
    <div className={styles.chrysBubbleWrapper}>
        <p className={styles.chrysBubble}>
            {text}
        </p>
    </div>
);

const PrasiBubble = ({ text }) => (
    <div className={styles.prasiBubbleWrapper}>
        <p className={styles.prasiBubble}>
            {text}
        </p>
    </div>
);

const renderCurrentDiscussion = (currentDiscussion) => {
    switch (currentDiscussion.talker) {
        case 'prasi':
            return <PrasiBubble text={currentDiscussion.content} />
        case 'kokki':
            return <KokkiBubble text={currentDiscussion.content} />
        case 'chrys':
            return <ChrysBubble text={currentDiscussion.content} />
        default:
            return <div>(unknown bubble type: [{currentDiscussion.talker}] - currentDiscussion.content)</div>
    }
}

const LernieChat = ({ map, currentDiscussion, isLastBubbleInDiscussion, processNextDiscussion}) => (
    <div className={styles.lernieWrapper}>
        <div className={styles.lernieBackground}/>
        { map.level === 1 && <p className={styles.lernieHint}>Clique pour poursuivre la discussion</p>}
        {currentDiscussion && renderCurrentDiscussion(currentDiscussion)}
        <div className={isLastBubbleInDiscussion ? styles.slideOutBottom : styles.slideInBottom}></div>
        <div className={styles.lernieOverlay} onClick={processNextDiscussion}/>
    </div>
)

export default LernieChat;
