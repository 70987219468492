import { withStateHandlers } from 'recompose';

const state = () => ({
    discussionIndex: 0,
    currentDiscussion: null,
    isLastBubbleInDiscussion: false,
    timerId: 0,
});

const handlers = {
        startTimer: (state, props) => ({ timerId }, { processNextDiscussion }) => {
            const { discussionIndex } = state;
            const { mode, map } = props;
            
            const discussionArray = mode === 'pre' ? map?.preDiscussion : map?.postDiscussion;
            const currentDiscussion = discussionArray?.[discussionIndex];
            const isLastBubbleInDiscussion = discussionIndex === discussionArray?.length;

            clearInterval(timerId);
            return {
                discussionIndex: discussionIndex + 1,
                currentDiscussion,
                isLastBubbleInDiscussion,
                timerId: 0 
            };
        },
        stopTimer: ({ timerId }) => () => clearInterval(timerId),
        processNextDiscussion: (state, props) => (event) => {
            const { discussionIndex, timerId } = state;
            const { mode, map, notifyChatEnded } = props;
    
            const discussionArray = mode === 'pre' ? map?.preDiscussion : map?.postDiscussion;
            const currentDiscussion = discussionArray[discussionIndex];
            const isLastBubbleInDiscussion = discussionIndex === discussionArray.length;

            if (isLastBubbleInDiscussion) {
                notifyChatEnded();
            }

            let updatedTimerId = timerId
            if (event) {
                clearInterval(timerId);
            }

            return {
                discussionIndex: discussionIndex + 1,
                currentDiscussion,
                isLastBubbleInDiscussion,
                timerId: updatedTimerId,
            };
        },
    };

export default withStateHandlers(
    state,
    handlers,
);

