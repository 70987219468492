import React from 'react';

import styles from './SmallModal.module.scss';

import { MODAL_TYPE_ALERT, MODAL_TYPE_INFO, MODAL_TYPE_NO_BUTTON, MODAL_TYPE_WARNING, MODAL_TYPE_OK } from './SmallModal.constants';

const mapStylesToType = {
 [MODAL_TYPE_ALERT]: { wrapper: styles.alertModalWrapper, background: styles.alertModalBackground },
 [MODAL_TYPE_INFO]: { wrapper: styles.infoModalWrapper, background: styles.infoModalBackground },
 [MODAL_TYPE_NO_BUTTON]: { wrapper: styles.noButtonModalWrapper, background: styles.noButtonModalBackground },
 [MODAL_TYPE_WARNING]: { wrapper: styles.warningModalWrapper, background: styles.warningModalBackground },
 [MODAL_TYPE_OK]: { wrapper: styles.okModalWrapper, background: styles.okModalBackground },
}

const SmallModal = ({ onClick, text, buttonCaption, type }) => {
  const modalStyles = mapStylesToType[type];
  return (
    <div className={modalStyles.wrapper} onClick={onClick}>
      <div className={modalStyles.background}>
        {text}
        <div className={styles.modalSeparator} />
        <p className={styles.modalButtonText}>{buttonCaption}</p>
      </div>
    </div>
  );

}

SmallModal.defaultProps = {
  onClick: () => {},
  type: MODAL_TYPE_WARNING,
};

export default SmallModal;
