import { connect } from 'react-redux';
import { actions as cardsListActions } from 'features/cardsList';

const mapStateToProps = ({ cardsList }) => ({ cardsList });
const mapDispatchToProps = {
  fetchCardsList: cardsListActions.fetchCardsList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;
