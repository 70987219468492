import React from 'react';

import styles from './CardDate.module.scss';

const CardDate = ({date, dateLabel, isBlurred, showMissMessage}) => {
  const clearLabel = dateLabel ? dateLabel : date;
  // eslint-disable-next-line multiline-ternary
  const blurredOrClearLabel = isBlurred ? (
    <span className={styles.blurredCard}>
      {
        clearLabel
          .toString()
          .split('')
          .map(() => 'S')
          .join()
      }
    </span>
  ): clearLabel

  return (
  <div className={styles.cardDateContainer}>
  <span className={`${styles.cardDate} ${showMissMessage && styles.cardDateShowRound}`} style={{opacity: isBlurred ? 0.3:1 }}>
    {blurredOrClearLabel}

  </span>
  </div>
)
    }
export default CardDate;