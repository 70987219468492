
const initialState = {
    areCardsOrdered: false,
    numberOfAttempts: 0,
    numberOfMoves: 0,
    cardDragged: null,
    startTime: new Date(),
    showMissMessage: false,
    outOfEnergy: false,
    outOfLives: false,
    outOfTime: false,
    isFirstRound: true,
};

export default initialState;
