import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import reducers from './reducers';
import { watchPlayer as playerSagas } from './operations';


export default reducers;
export { types };
export { actions };
export { playerSagas };
export { selectors };