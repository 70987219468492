import React from 'react';

import styles from './CardTitle.module.scss';

const CardTitle = ({name}) => (
  <div className={styles.cardTitle}>
    {name}
  </div>
)

export default CardTitle;