import { select, takeEvery, put, all } from 'redux-saga/effects';

import { checkFulfilledConditionsForMapEvent } from 'services/lifecycle';

import { actions as mapActions, selectors as mapSelectors } from 'features/map';

import { selectors as playerSelectors } from 'features/player';

import * as types from './types';

export function* genericLifecycleProcess(eventName, defaultAction) {
    const player = yield select(playerSelectors.getPlayerSelector);
    const filteredMapEvents = yield select(mapSelectors.getMapEvents, eventName);
    let exitLifecycle = false;
    yield all(filteredMapEvents?.map(mapEvent => {
        const fulfilledConditions = checkFulfilledConditionsForMapEvent(mapEvent, { player });
        if (fulfilledConditions) {
            exitLifecycle = exitLifecycle || mapEvent.exitLifecycle;
            return mapEvent.do && put(mapActions[mapEvent.do](mapEvent.withParams));
        }
    }));

    if (!exitLifecycle) {
        yield put(defaultAction());
    }
}

export function* lernieChatWillStartSaga() {
    yield genericLifecycleProcess('lernieStartsTalking', mapActions.startLernieChat)
}

export function* lernieEndedSpeakingSaga() {
    yield genericLifecycleProcess('lernieIntroFinished', mapActions.openStartGameDialog)
}

export function* lernieEndedDebriefSaga() {
    yield genericLifecycleProcess('gameRewards', mapActions.showCardsRewards);
}

export function* lifecycleGameWillStartSaga() {
    yield genericLifecycleProcess('gameWillStart', mapActions.mainGame);
}

export function* alternativeLernieChatSaga() {
    const mapEventsLernieStartsTalking = yield select(mapSelectors.getMapEvents, 'lernieStartsTalking');
    yield put(mapActions.updateMapPreTalkWith(mapEventsLernieStartsTalking[0].withParams));
    yield put(mapActions.startLernieChat());
}

export function* watchLifecycles() {
    yield takeEvery(types.LIFECYCLE_LERNIE_CHAT_WILL_START, lernieChatWillStartSaga);
    yield takeEvery(types.LIFECYCLE_LERNIE_ENDED_SPEAKING, lernieEndedSpeakingSaga);
    yield takeEvery(types.LIFECYCLE_LERNIE_ENDED_DEBRIEF, lernieEndedDebriefSaga);
    yield takeEvery(types.LIFECYCLE_LERNIE_ALTERNATIVE_CHAT, alternativeLernieChatSaga);
    yield takeEvery(types.LIFECYCLE_GAME_WILL_START, lifecycleGameWillStartSaga)
}
