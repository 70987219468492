import React from 'react';

import { SIZE_THREE_QUARTERS, SIZE_FULL, SIZE_HALF, BUTTON_HOME } from './Button.constants';

import styles from './Button.module.scss';

const Button = ({size, type, isDisabled, onClick}) => {
    const buttonClassName = styles[`${type}Button${isDisabled ? 'Disabled' : ''}`];
    let overriddenStyles = {};
    if (size === SIZE_HALF) {
        overriddenStyles.width = '57px';
        overriddenStyles.height = '57px';
    }
    if (size === SIZE_THREE_QUARTERS) {
        overriddenStyles.width = '82px';
        overriddenStyles.height = '82px';
    }

    return (
        <button className={buttonClassName} style={overriddenStyles} onClick={e => isDisabled ? () => {} : onClick(e)}>
        </button>
    );
}

Button.defaultProps = {
    onClick: () => {},
    isDisabled: false,
    type: BUTTON_HOME,
    size: SIZE_FULL,
};

export default Button;