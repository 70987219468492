import React from 'react';
import t from 'prop-types'

import {
  CardImage,
  CardTitle,
  CardType,
  CardDate,
  CardValue,
  CardDescription,
} from "components";

import styles from './StaticCard.module.scss';

const Card = ({ name, date, dateLabel, image, value, description, theme, isDragging, index }) => (
  <div
    className={styles.card}
  >
    <CardImage image={image} />
    <CardType theme={theme} />
    <CardTitle name={name} />
    <CardDate date={date} dateLabel={dateLabel} />
    <CardValue value={value} />
    <CardDescription description={description} />
  </div>);

Card.propTypes = {
  name: t.string,
  date: t.number,
  value: t.string,
  description: t.string,
};

export default Card;
