import { compose, lifecycle } from 'recompose';

import CardBoard from './EpochGameRewards.design';
import CardBoardLifeCycle from './EpochGameRewards.lifecycle';
import withConnect from './EpochGameRewards.connect';
import withProps from './EpochGameRewards.props';

export default compose(
    withConnect,
    lifecycle(CardBoardLifeCycle),
    withProps,
)(CardBoard);
