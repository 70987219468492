import React from 'react';

import { PortalAwareCard } from 'components';

import styles from './SortGameBoard.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const onDragEnd = moveCardsFromTo => e => {
  if (e.reason === 'DROP' && e.destination !== null && e.source.index !== e.destination.index) {
    moveCardsFromTo(e.source.index, e.destination.index);
  }
}

const showCard = ({
  nbCards,
  areDatesBlurred,
  round,
}
) => (card, index) => (
  <Draggable
    index={index}
    draggableId={card._id}
    key={card._id}
  >
    {
      (provided, snapshot) =>
        <PortalAwareCard
          provided={provided}
          snapshot={snapshot}
          card={card}
          index={index}
          areDatesBlurred={areDatesBlurred}
          nbCards={nbCards}
          round={round}
        />
    }
  </Draggable>
);

const SortGameBoard = props => {
  const { cardsDetails, moveCardsFromTo, clearCurrentDragCard, areDatesBlurred, round } = props;
  return (
    <DragDropContext onDragEnd={onDragEnd(moveCardsFromTo)}>
      <div className={`${styles.cardBoardBackground} ${round?.showMissMessage && styles.cardBoardBackgroundShowMissMessage}`}>
        <div
          className={styles.cardBoard}
        >
          <Droppable
            droppableId="cardBoardDropArea"
            direction="horizontal"
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ?
                  styles.cardsRowIsDragging :
                  styles.cardsRow
                }
              >
                {cardsDetails.map(showCard({
                  nbCards: cardsDetails.length,
                  clearCurrentDragCard,
                  areDatesBlurred,
                  round,
                }))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default SortGameBoard;
