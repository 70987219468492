import { compose, lifecycle } from 'recompose';

import CardBoard from './CardsRewards.design';
import CardBoardLifeCycle from './CardsRewards.lifecycle';
import withConnect from './CardsRewards.connect';

export default compose(
    withConnect,
    lifecycle(CardBoardLifeCycle),
)(CardBoard);
