import React from "react";

import styles from "./CardValue.module.scss";

const cardStyle = value => styles[`cardValue-level${value}`];

const CardValue = ({value}) => (
  <div className={styles.cardValueContainer}>
    <div className={styles.cardValueOuter}>
      <div className={cardStyle(value)}>&nbsp;</div>
    </div>
  </div>
);

export default CardValue;
