import { compose } from 'recompose';

import Menu from './Menu.design';
import withConnect from './Menu.connect';
import withLifecycle from './Menu.lifecycle';
import withStateHandlers from './Menu.stateHandlers';

export default compose(
    withConnect,
    withStateHandlers,
    withLifecycle,
)(Menu);
