import React from 'react';
import { createPortal } from 'react-dom'

import { Card } from 'components';

import styles from './PortalAwareCard.module.scss';

const portal = document.getElementsByClassName('drag-drop-portal')[0];

const PortalAwareCard = ({
  provided,
  snapshot,
  card,
  index,
  areDatesBlurred,
  round,
}) => {
  const child = (<div
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    className={styles.portalContainer}
    key={card._id}
  >
    <Card
      id={card._id}
      index={index}
      name={card.name}
      image={card.illustration}
      date={card.date}
      dateLabel={card.dateLabel}
      description={card.description}
      value={card.cardLevel}
      theme={card.theme}
      areDatesBlurred={areDatesBlurred}
      showMissMessage={round?.showMissMessage}
      displayType={card.displayType}
    />
  </div>);
  return snapshot.isDragging ? createPortal(child, portal) : child;
}

export default PortalAwareCard;
