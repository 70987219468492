import { connect } from 'react-redux';

import { actions as campaignGameActions } from 'features/campaignGame';
import {
  selectors as playerSelectors 
} from 'features/player';
import {
  actions as roundActions,
  selectors as roundSelectors
} from 'features/round';

const mapStateToProps = (state) => ({
  round: roundSelectors.getRoundParams(state),
  player: playerSelectors.getPlayerSelector(state),
});

const mapDispatchToProps = {
  closeWrongOrderModal: roundActions.closeWrongOrderModal,
  campaignGameInit: campaignGameActions.campaignGameInit,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;
