import { lifecycle } from 'recompose';
import { getGameInfos } from 'services/http';

export default lifecycle({
    async componentDidMount() {
        try {
        const { data: { cardsDetails } } = await getGameInfos();
        this.props.setNewGame({
            cardsDetails,
        });
        } catch (e) {
            console.error(e);
        }
    }
});
