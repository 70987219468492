import { connect } from 'react-redux';
import {
  actions as mapActions,
  selectors as mapSelectors,
} from 'features/map';
import { actions as lifecycleActions } from 'features/lifecycle';

const mapStateToProps = (state) => ({
  map: mapSelectors.getCurrentMap(state)
});
const mapDispatchToProps = {
  endLernieChat: mapActions.endLernieChat,
  lifecycleGameWillStart: lifecycleActions.lifecycleGameWillStart,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;
