import React from 'react';
import styles from './EnergyBar.module.scss';

const EnergyBar = ({ value }) => (
    <div className={styles.energyBar}>
        <span className={styles.energyBarText}>
            {value}
        </span>
    </div>
);

export default EnergyBar;
