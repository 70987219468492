import { withHandlers } from 'recompose';

import { isPlayerNameAvailable, isEmailAlreadyRegistered, registerPlayer } from 'services/http';
import { hideRegister } from 'features/map/actions';

const STEP_NAME = 1;
const STEP_EMAIL = 2;
const STEP_PASSWORD = 4;
const STEP_THANK_YOU = 5;

const handlers = {
    nextStepAsyncHandler: ({step, name, email, password1, debriefLevel, updateStateHandlerProps}) => async (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        switch (step) {
            case STEP_NAME: {
                const response = await isPlayerNameAvailable(name);
                if (response.data.isNameAvailable) {
                    updateStateHandlerProps({
                        step: step + 1,
                        nameAlreadyTaken: false,
                    });
                } else {
                    updateStateHandlerProps({
                        nameAlreadyTaken: true,
                    })
                }
                break;
            }

            case STEP_EMAIL: {
                const response = await isEmailAlreadyRegistered(email);
                if (!response.data.isEmailRegistered) {
                    updateStateHandlerProps({
                        step: step + 1,
                        emailAlreadyRegistered: false,
                    });
                } else {
                    updateStateHandlerProps({
                        emailAlreadyRegistered: true,
                    })
                }
                break;
            }

            case STEP_PASSWORD: {
                const response = await registerPlayer(name, email, password1);
                if (response.data.isPlayerRegistered) {
                    updateStateHandlerProps({
                        step: step + 1,
                        isPlayerRegistered: true,
                    });
                } else {
                    updateStateHandlerProps({
                        isPlayerRegistered: false,
                    })
                }
                break;
            }
            
            case STEP_THANK_YOU: {
                hideRegister();
                break;
            }
            default:
                updateStateHandlerProps({
                    step: step + 1,
                });
        }
        
    },
};


export default withHandlers(
    handlers,
);