import { lifecycle } from 'recompose';

export default lifecycle({
    componentDidMount() {
        this.props.fetchPlayerInformationsWithMap();
        console.log('***********');
        console.log(this.props.circleRef);
        console.log('***********');
    },

    componentDidUpdate() {
        console.log('*****-*****');
        console.log(this.props.circleRef);
        console.log('***********');
    }
});
