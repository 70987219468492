import React from 'react';

import styles from './WizardModal.module.scss';

const isNextStepEnabled = props => props.step < props.stepsDesign.length - 1;
const isPreviousStepEnabled = props => !(props.step === 0 || props.step === props.stepsDesign.length - 1);

const WizardModal = (props) => {
    const { stepsDesign, onCloseModal, step, nextStepAsyncHandler, previousStep } = props;
    return (
        <div className={styles.successWrapper}>
            <div className={styles.success}>
                {stepsDesign[step] && stepsDesign[step](props)}
                <div className={styles.registerSeparator} />
                <div className={styles.bottomBar}>
                    <div className={styles.laterButton} onClick={onCloseModal} />
                    <div
                        className={
                        isPreviousStepEnabled(props) ?
                            styles.previousButton :
                            styles.previousButtonDisabled
                        }
                        onClick={e => { isPreviousStepEnabled(props) && previousStep(e)}}
                    />
                    <div
                        className={isNextStepEnabled(props) ?
                            styles.nextButton :
                            styles.nextButtonDisabled
                        }
                        onClick={e => { isNextStepEnabled(props) && nextStepAsyncHandler(e) }}
                    />
                </div>
            </div>
        </div>
    );
}

WizardModal.defaultProps = {
    onCloseModal: () => {},
    stepsDesign: [
        ({ step }) => (<div className={styles.wizardModalDialog}>
            Blah 1!
        </div>),
        (props) => (<div className={styles.wizardModalDialog}>
            Blah 2!
        </div>),
        (props) => (<div className={styles.wizardModalDialog}>
            Blah 3!
        </div>),
        ({ step }) => (<div className={styles.wizardModalDialog}>
            Blah 4!
        </div>),
        (props) => (<div className={styles.wizardModalDialog}>
            Blah 5!
        </div>),
        (props) => (<div className={styles.wizardModalDialog}>
            Blah 6!
        </div>),
    ]
};

export default WizardModal;
