import React from 'react';

import styles from './OpenedMapDialog.module.scss';

const OpenedMapDialog = ({ map, endLernieChat, lifecycleGameWillStart }) =>
  (
    <div className={styles.openedMapDialogWrapper} onClick={endLernieChat}>
      <div className={styles.openedMapDialog} onClick={lifecycleGameWillStart}>
        <p className={styles.openedMapDialogTitle}>{map.levelName}</p>
        <p className={styles.openedMapDialogSubTitle}>Niveau {map.level}</p>
        <p className={styles.openedMapDialogDetails}>{map.levelDescription}</p>
        <div className={styles.openedMapDialogSeparator} />
        <p className={styles.openedMapDialogButtonText}>Jouer !</p>
      </div>
    </div>
  );

export default OpenedMapDialog;
