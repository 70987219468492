import { lifecycle } from 'recompose';

const withLifeCycle = {
    componentDidUpdate(prevProps) {
        if (prevProps.step !== this.props.step) {
            const { step } = this?.props;
            if (step === 1) {
                document.querySelector('#nameRegisterForm').focus();
            }
            if (step === 2) {
                document.querySelector('#emailRegisterForm').focus();
            }
            if (step === 4) {
                document.querySelector('#password1RegisterForm').focus();
            }
        }
        if (prevProps.nameAlreadyTaken === false && this.props.nameAlreadyTaken === true) {
            document.querySelector('#nameRegisterForm').focus();
        }
        if (prevProps.emailAlreadyRegistered === false && this.props.emailAlreadyRegistered === true) {
            document.querySelector('#emailRegisterForm').focus();
        }
    }
};

export default lifecycle(withLifeCycle);