import LostRound from './LostRound.design';
import { compose } from 'recompose';

import withConnect from './LostRound.connect';
import { withRouter } from 'react-router-dom';

export default compose(
    withConnect,
    withRouter,
)(LostRound);
