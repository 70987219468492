const iconDefinitions = [
    {
        iconPath: 'default',
        iconSize: [42, 32],
    },
    {
        iconPath: 'default-off',
        iconSize: [42, 32],
        className: 'lockedLevel',
    },
    {
        iconPath: 'desert-card',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-card-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-save',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-save-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-life',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-life-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-energy',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-energy-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-time',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-time-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-hidden',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-hidden-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-crossroads',
        iconSize: [80, 106],
    },
    {
        iconPath: 'desert-crossroads-off',
        iconSize: [80, 106],
    },
    {
        iconPath: 'desert-large',
        iconSize: [120, 88],
    },
    {
        iconPath: 'desert-large-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'desert-large-arena',
        iconSize: [119, 97],
    },
    {
        iconPath: 'desert-large-arena-off',
        iconSize: [119, 97],
    },
    {
        iconPath: 'forest',
        iconSize: [60, 44],
    },
    {
        iconPath: 'forest-off',
        iconSize: [60, 44],
    },
    {
        iconPath: 'level-special',
        iconSize: [68, 48],
    },
    {
        iconPath: 'level-special-off',
        iconSize: [68, 48],
    },
    {
        iconPath: 'jungle-crossroad',
        iconSize: [92, 154],
    },
    {
        iconPath: 'jungle-crossroad-off',
        iconSize: [92, 154],
    },
    {
        iconPath: 'jungle-leave',
        iconSize: [50, 61],
    },
    {
        iconPath: 'jungle-leave-off',
        iconSize: [50, 61],
    },
    {
        iconPath: 'jungle',
        iconSize: [42, 32],
    },
    {
        iconPath: 'jungle-off',
        iconSize: [42, 32],
    },
    {
        iconPath: 'jungle-leave2',
        iconSize: [62, 62],
    },
    {
        iconPath: 'jungle-leave2-off',
        iconSize: [62, 62],
    },
    {
        iconPath: 'jungle-exclamation',
        iconSize: [60, 46],
    },
    {
        iconPath: 'jungle-exclamation-off',
        iconSize: [60, 46],
    },
    {
        iconPath: 'jungle-trex',
        iconSize: [66, 49],
    },
    {
        iconPath: 'jungle-trex-off',
        iconSize: [66, 49],
    },
    {
        iconPath: 'jungle-fight',
        iconSize: [60, 46],
    },
    {
        iconPath: 'jungle-fight-off',
        iconSize: [60, 46],
    },
    {
        iconPath: 'beach',
        iconSize: [42, 32],
    },
    {
        iconPath: 'beach-off',
        iconSize: [42, 32],
    },
];

export default iconDefinitions;