import { connect } from 'react-redux';
import {
    actions as mapActions,
    selectors as mapSelectors,
} from 'features/map';

import {
    selectors as playerSelectors
} from 'features/player';

import { actions as campaignGameActions } from 'features/campaignGame';
import { actions as lifecycleActions } from 'features/lifecycle';

const mapStateToProps = (state) => ({
    playerName: playerSelectors.getPlayerName(state),
    openedStartLevelDialog: mapSelectors.isOpenedStartLevelDialog(state),
    showDebrief: mapSelectors.showDebrief(state),
    lernieChatOpened: mapSelectors.isLernieChatOpened(state),
    showCardsRewards: mapSelectors.showCardsRewards(state),
    showRound: mapSelectors.showRound(state),
    showEpochRewards: mapSelectors.showEpochRewards(state),
    showEpochGame: mapSelectors.showEpochGame(state),
    showSuccesses: mapSelectors.showSuccesses(state),
    showRegisterForm: mapSelectors.showRegisterForm(state),
    successes: mapSelectors.getSuccesses(state),
    events: mapSelectors.getEvents(state),
});

const mapDispatchToProps = {
    closeLernieChat: mapActions.closeLernieChat,
    openStartGameDialog: mapActions.openStartGameDialog,
    lifeCycleLernieEndedSpeaking: lifecycleActions.lifecycleLernieEndedSpeaking,
    lifeCycleLernieEndedDebrief: lifecycleActions.lifecycleLernieEndedDebrief,
    campaignGameInit: campaignGameActions.campaignGameInit,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false });
