import React from 'react';

import { SmallModal } from 'components';

import styles from './EpochGameEndGameModal.module.scss';

const EpochGameEndGameModal = ({closeEpochGame, showDebrief, startLernieChat, score}) => (
<SmallModal
    onClick={() => {
      closeEpochGame();
      showDebrief();
      startLernieChat();
    }}
    text={(
      <p className={styles.modalContent}>
        <h3 className={styles.modalContentTitle}>Fin du jeu !</h3>
        <span>Score: {score}</span></p>)}
    buttonCaption="Récompenses" />
);

EpochGameEndGameModal.defaultProps = {
  closeEpochGame: () => {},
  showDebrief: () => {},
  startLernieChat: () => {},
  score: 0,
};

export default EpochGameEndGameModal;