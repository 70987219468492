import {createRef} from 'react';

import { compose, withProps } from 'recompose';

import { withRouter } from 'react-router-dom';

import withConnect from './GameMap.connect';
import withLifeCycle from './GameMap.lifecycle';
import GameMap from './GameMap.design';

export default compose(
    withRouter,
    withProps({
        circleRef: createRef(),
    }),
    withConnect,
    withLifeCycle,
)(GameMap);