import React from 'react';

import { withProps } from 'recompose';

import styles from './EpochGameRewards.module.scss';

const firstWizardScreen = (props) => (wizardProps) => (
    <p className={styles.congratulationsScreen}>
        Résumé de la partie
    </p>
);

const secondWizardScreen = (props) => (wizardProps) => (
    <div>
        blah 2
    </div>
);

const props = props => ({
    modalSteps: [
        firstWizardScreen(props),
        secondWizardScreen(props),
    ],
});

export default withProps(props);