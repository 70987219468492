import React from 'react';

import styles from './EpochGameCategory.module.scss';
import { Droppable } from 'react-beautiful-dnd';
import CardDate from 'components/CardDate';

const EpochGameCategory = ({id, category, isOk, isWrong, onClick}) => {
    return (
        <Droppable
            droppableId={id}
        >
            {(provided, snapshot) => {
                return (
                    <div
                        onClick={() => onClick(id)}
                        ref={provided.innerRef}
                        className={snapshot.isDraggingOver ? styles.categoryAreaOver : styles.categoryArea}
                        style={{ backgroundImage: `url(/epochs/${category.image}.jpg)` }}
                    >
                        <h4 className={styles.categoryAreaTitle}>{category.name}</h4>
                        {isOk && <div className={styles.categoryIsOk}>OK !</div>}
                        {isWrong && <div className={styles.categoryIsWrong}>Raté !</div>}
                        <div className={styles.categoryAreaMain}>
                            <div className={styles.categoryLeftDate}>
                                <CardDate date={category.startDate} dateLabel={category.startDateLabel} />
                            </div>
                            <div className={styles.categoryCentralZone}>{provided.placeholder}</div>
                            <div className={styles.categoryRightDate}>
                                <CardDate date={category.endDate} dateLabel={category.endDateLabel} />
                            </div>
                        </div>
                    </div>)
            }
            }
        </Droppable>)
}

export default EpochGameCategory;