import React from "react";

import styles from "./CardDescription.module.scss";

const CardDescription = ({ description }) => (
  <div className={styles.cardDescriptionContainer}>
    <div className={styles.cardDescriptionWrapper}>
      <div className={styles.cardDescription}>{description}</div>
    </div>
  </div>
);

export default CardDescription;
