import { withStateHandlers } from 'recompose';

const initialState = () => ({
    cardsDetails: [],
});

const handlers = {
    setNewGame: () => ({ cardsDetails }) => ({
        cardsDetails: [...cardsDetails],
    }),
}

export default withStateHandlers(
    initialState,
    handlers,
);
