const gameStrengths = {
  1: {
    nbCards: 3,
    previousLevel: 1,
    nextLevel: 2,
    maskedDates: false,
    maxTime: 0,
  },
  2: {
    nbCards: 4,
    previousLevel: 1,
    nextLevel: 3,
    maskedDates: false,
    maxTime: 0,
  },
  3: {
    nbCards: 4,
    previousLevel: 2,
    nextLevel: 4,
    maskedDates: true,
    maxTime: 0,
  },
  4: {
    nbCards: 4,
    previousLevel: 3,
    nextLevel: 5,
    maskedDates: true,
    maxTime: 60,
  },
  5: {
    nbCards: 5,
    previousLevel: 4,
    nextLevel: 6,
    maskedDates: true,
    maxTime: 70,
  },
  6: {
    nbCards: 6,
    previousLevel: 5,
    nextLevel: 7,
    maskedDates: true,
    maxTime: 75,
  },
  7: {
    nbCards: 7,
    previousLevel: 6,
    nextLevel: 8,
    maskedDates: true,
    maxTime: 80,
  },
  8: {
    nbCards: 7,
    previousLevel: 7,
    nextLevel: 9,
    maskedDates: true,
    maxTime: 70,
  },
  9: {
    nbCards: 7,
    previousLevel: 8,
    nextLevel: 10,
    maskedDates: true,
    maxTime: 60,
  },
  10: {
    nbCards: 7,
    previousLevel: 9,
    nextLevel: 10,
    maskedDates: true,
    maxTime: 50,
  }
};

export default gameStrengths;
export const currentGameStrength = level => gameStrengths[level];
