import React from 'react';

import { Card } from 'components';

import styles from './CardsRewards.module.scss';

const showCard = (card, index) => (
    <Card
      key={card._id}
      id={card._id}
      index={index}
      name={card.name}
      image={card.illustration}
      date={card.date}
      dateLabel={card.dateLabel}
      description={card.description}
      value={card.cardLevel}
      theme={card.theme}
      disableDrag={true}
    />
    
);

const CardsRewards = props => {
  const { map, nextLevel } = props;
  const cards = map?.rewards?.cardsDetails;
  if (!cards) {
    return null;
  }
  const boardWidth = (cards.length*15) + ((cards.length+1)*5);
  return (
    <div className={styles.background}>
      <div className={styles.cardBoard} style={{maxWidth: `${boardWidth}rem`}}>
        <p className={styles.cardsWon}>Cartes gagnées</p>
        <div className={styles.cardsWonContainer}>
          {Object.values(cards).map(showCard)}
        </div>
      </div>
      <div className={styles.cardBoardOverlay} onClick={nextLevel}></div>
    </div>
  );
};

export default CardsRewards;
