import { select, takeEvery, put } from 'redux-saga/effects';
import * as actions from './actions';

import { 
  checkCards,
  getPlayerInfosWithMapLocations
} from 'services/http';
import { types as playerTypes } from '.';
import { types as roundTypes }  from 'features/round';
import { getCurrentMap } from 'features/map/selectors';

export function* foundRightOrder() {
  const map = yield select(getCurrentMap);
  if (!map && !map?.disableStrengthUpdate) {
    yield put(actions.increaseGameStrength());
  }
  yield checkCards();
}

export function* fetchPlayer() {
  try {
    const { data } = yield getPlayerInfosWithMapLocations();
    yield put(actions.playerFetchSuccess(data));
  } catch (e) {
    if (e.response?.status === 401) {
      localStorage.removeItem('token');
    } else {
      throw e;
    }
  }
}

export function* watchPlayer() {
  yield takeEvery(roundTypes.ROUND_FOUND_ORDER, foundRightOrder);
  yield takeEvery(playerTypes.PLAYER_FETCH, fetchPlayer);
}