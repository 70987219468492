import React from 'react';

import { Link } from 'react-router-dom';
import { MenuButton } from 'components';

import styles from './Menu.module.scss';

const Menu = (props) => {
  return props.preloadedImages ?

    (<div className={styles.menuWrapper}>
      <div className={styles.appTitle}>
        <span className={styles.appTitleGrey}>Time-Wreck!</span>Time-Wreck!
    </div>
      <div className={styles.menu}>
        <Link to="/game">
          <MenuButton>Campagne</MenuButton>
        </Link>
        {props?.player?.successes?.['c373560e-5ee7-42f9-b0b4-f76d25eb0f49'] && <Link to="/collection">
          <MenuButton>Ma collection</MenuButton>
        </Link>
      }
        {/* <Link to="/round">
                <Button>Partie rapide</Button>
            </Link>
            <Link to="/cards">
                <Button>Records</Button>
</Link>
      <Link to="/cards">
        <Button>Charger</Button>
      </Link>*/}
      </div>
    </div>) :
    <div>Chargement de Timewreck...</div>
};

export default Menu;
