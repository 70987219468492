export const getCurrentMap = (state, props) => state.map;

export const getMapEvents = (state, lifeCycleKey) => state?.map?.events?.filter(event => event.lifecycle === lifeCycleKey);

export const isOpenedStartLevelDialog = state =>  state.map.openedStartLevelDialog;
export const showDebrief = state => state.map.showDebrief;
export const isLernieChatOpened = state => state.map.lernieChatOpened;
export const showCardsRewards = state => state.map.showCardsRewards;
export const showRound = state => state.map.showRound;
export const showEpochRewards = state => state.map.showEpochRewards;
export const showEpochGame = state => state.map.showEpochGame;
export const showSuccesses = state => state.map.showSuccesses;
export const showRegisterForm = state => state.map.showRegisterForm;
export const getSuccesses = state => state.map.successes;
export const getEvents = state => state.map.events;
