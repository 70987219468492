import { withStateHandlers } from 'recompose';
import { currentGameStrength } from 'configuration/gameStrengths';

const state = (props) => ({
    index: 0,
    isVisible: false,
    counter: props?.map?.forcedTimeSeconds ? props.map.forcedTimeSeconds * 1000 : maxTime(props.player.gameStrength) * 1000,
    timerId: 0,
});


const timeFactory = (maxTime) => maxTime === 0 ?
    counter => counter + 100 :
    counter => counter - 100


const maxTime = (gameStrength) => currentGameStrength(gameStrength).maxTime;

const handlers = {
    startTimer: (state, props) => ({ timerId }, { updateValue }) => {
        clearInterval(timerId);
        return {
            timerId: setInterval(updateValue, 100)
        };
    },
    stopTimer: ({ timerId }) => () => clearInterval(timerId),
    updateValue: (state, props) => () => {
        if (props.round?.areCardsOrdered) {
            return ({ counter: 60 });
        }
        const { counter, timerId } = state;

        if (counter <= 0) {
            clearInterval(timerId)
            props.handleOutOfTime();
            return ({counter: 0});
        }
        const increaseDecreaseCounter = timeFactory(maxTime(props.player.gameStrength));
        return ({ counter: increaseDecreaseCounter(counter) })
    },
    resetValue: (state, props) => () => {
        return ({ counter: maxTime(props.player.gameStrength) })
    },
};

const ChronometerStateHandlers = withStateHandlers(state, handlers);

export default ChronometerStateHandlers;
