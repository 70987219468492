export const lifeCycleConditions = {
  notRegistered: ({player, map}) => !player.isRegistered,
  isRegistered: ({player, map}) => player.isRegistered,
  alwaysTrue: ({player, map}) => true,
};

export const checkAllConditionsForEventReducer = ({player}) => (isTrue, currentConditionKey) => isTrue && lifeCycleConditions[currentConditionKey]({player});


export const checkFulfilledConditionsForMapEvent = (mapEvent, { player }) => mapEvent
  .conditions
  .reduce(checkAllConditionsForEventReducer({ player }), true);
