import React from 'react';
import t from 'prop-types';

import {
  CardImage,
  CardTitle,
  CardType,
  CardDate,
  CardValue,
  CardDescription
} from 'components';

import styles from './Card.module.scss';

const Card = ({
  name,
  date,
  dateLabel,
  image,
  value,
  description,
  theme,
  areDatesBlurred,
  isGrayed,
  showMissMessage,
  displayType,
}) => {
  const CardDesign = (<div
    className={
      displayType === 'mini' ?
        styles.cardMini :
        isGrayed ?
          styles.cardDisabled :
          styles.card
    }
    >
      <CardImage image={image} />
      <CardType theme={theme} />
      <CardTitle name={name} />
      <CardDate
        date={date}
        dateLabel={dateLabel}
        isBlurred={areDatesBlurred}
        showMissMessage={showMissMessage}
      />
      {displayType !== 'mini' && <CardDescription description={description} /> }
      <CardValue value={value} />
    </div>);

  return CardDesign;
};
Card.propTypes = {
  name: t.string,
  date: t.number,
  value: t.number,
  description: t.string
};

export default Card;
