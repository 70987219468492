import { withProps } from 'recompose';

import {
  NB_MAX_LIVES,
  NB_MAX_ENERGY,
} from 'configuration/parameters';

export default withProps(({
    map: {
      disableXp,
      disableLives,
      disableStrength,
      disableEnergy,
      disableTime,
      levelHint,
      maxEnergy,
    },
    player: {
      playerLevel,
      gameStrength,
    },
    round: {
      outOfEnergy: isOutOfEnergy,
      outOfTime: isOutOfTime,
    },
    cardsDetails,
    numberOfMoves,
    numberOfAttempts,
    moveCardsFromTo,

    handleOutOfTime,

    checkCardsOrder,
    campaignGameInit,
  }) => {

  const mapEnergy = maxEnergy ? maxEnergy : NB_MAX_ENERGY;
  const energy = Math.max(0, Math.min(mapEnergy, mapEnergy - numberOfMoves)); 

  return ({

    cardsDetails,

    disableXp,
    disableLives,
    disableStrength,
    disableEnergy,
    disableTime,
    levelHint,

    playerLevel,
    gameStrength,

    nbLives: Math.max(0, Math.min(NB_MAX_LIVES, NB_MAX_LIVES - numberOfAttempts)),
    energy,

    isOutOfEnergy,
    isOutOfTime,

    handleOutOfTime,
    nbMaxEnergy: maxEnergy ? maxEnergy : NB_MAX_ENERGY,

    moveCardsFromTo,
    campaignGameInit,

    checkCardsOrder,
  });
})