import React from 'react';

import styles from './HeaderBar.module.scss';

const HeaderBar = ({ player }) => (
  <div className={styles.headerBar}>
    <div className={styles.headerLeftBlock}> </div>
    <div className={styles.headerTitle}>Bordel Temporel</div>
    <div className={styles.avatar}>
      <div className={styles.avatarLevel}>Niveau: {player.playerLevel}</div>
    </div>
  </div>
);

export default HeaderBar;
