import React from 'react';

import styles from './Success.module.scss';

const Successes = ({ hideSuccesses, map }) => (
    <div className={styles.successWrapper} onClick={hideSuccesses}>
        <div className={styles.success}>
            <p className={styles.successText}>{Object.values(map.successes)[0].title}</p>
            <div className={styles[`successMedalLevel${Object.values(map.successes)[0].groupLevel}`]}></div>
            <p className={styles.successDetails}>
                {Object.values(map.successes)[0].description}
            </p>
            <div className={styles.successSeparator} />
            <div className={styles.successButtonText}>Suite!</div>
        </div>
    </div>
);
export default Successes;