import axios from 'axios';

import { API_BASE_URI } from 'configuration/environment';
import { getJwtToken } from './localStorage';


const privateRouteHeaders = () => ({ headers: { 'Authorization': `Bearer ${getJwtToken()}` } });


// Public routes
export const registerClient = () => axios.post(`${API_BASE_URI}/register-client`);

// Private routes

// player
export const getPlayerInfos = () => axios.get(`${API_BASE_URI}/player`, privateRouteHeaders());
export const getPlayerInfosWithMapLocations = () => axios.get(`${API_BASE_URI}/player/map-locations`, privateRouteHeaders());
//export const getPlayerCollection = () => axios.get(`${API_BASE_URI}/player/collection`, privateRouteHeaders());
export const isPlayerNameAvailable = name => axios.post(`${API_BASE_URI}/player/is-name-available`, { name }, privateRouteHeaders());
export const isEmailAlreadyRegistered = email => axios.post(`${API_BASE_URI}/player/is-email-registered`, { email }, privateRouteHeaders());
export const registerPlayer = (name, email, password)=> axios.post(`${API_BASE_URI}/player/register`, { name, email, password }, privateRouteHeaders());

// cards
export const getAllCards = () => axios.get(`${API_BASE_URI}/history-cards/list`, privateRouteHeaders());

// map
export const getLevelInfos = levelId => axios.get(`${API_BASE_URI}/map/${levelId}`, privateRouteHeaders());

// game
export const getGameInfos = () => axios.post(`${API_BASE_URI}/game/infos`, {}, privateRouteHeaders());
export const checkGameCards = () => axios.post(`${API_BASE_URI}/game/check`, {}, privateRouteHeaders());
export const getGameRewards = () => axios.post(`${API_BASE_URI}/game/rewards`, {}, privateRouteHeaders());
export const triggerNextLevel = () => axios.post(`${API_BASE_URI}/game/next-level`, {}, privateRouteHeaders());

export const moveCard = (from, to) => axios.post(`${API_BASE_URI}/round/move`, { from, to }, privateRouteHeaders());
export const checkCards = () => axios.post(`${API_BASE_URI}/round/check`, {}, privateRouteHeaders());

// arena
export const createArena = () => {
    return axios.post(`${API_BASE_URI}/arena/create`, {}, privateRouteHeaders());
}

// epoch game
export const createEpochGame = () => axios.post(`${API_BASE_URI}/epoch-game/new`, {}, privateRouteHeaders());
export const moveEpochCard = (epochId) => axios.post(`${API_BASE_URI}/epoch-game/move`, { epochId }, privateRouteHeaders());
export const endEpochGame = () => axios.post(`${API_BASE_URI}/epoch-game/end-game`, {}, privateRouteHeaders());
