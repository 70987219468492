import {
  MAPLOCATION_FETCH,
  MAPLOCATION_FETCH_SUCCESS,
  MAP_OPEN_START_LEVEL_DIALOG,
  MAPLOCATION_FETCH_ERROR,
  MAP_CLOSE_START_DIALOG,
  MAP_MAIN_GAME,
  MAP_START_GAME,
  MAP_DEBRIEF_LEVEL,
  MAP_SHOW_CARDS_REWARDS,
  MAP_HIDE_CARDS_REWARDS,
  MAP_FETCH_CURRENT_GAME_REWARDS,
  MAP_FETCH_CURRENT_GAME_REWARDS_SUCCESS,
  MAP_FETCH_CURRENT_GAME_REWARDS_FAIL,
  MAP_NEXT_LEVEL,
  MAP_SHOW_SUCCESSES,
  MAP_HIDE_SUCCESSES,

  MAP_HIDE_ROUND,
  MAP_SHOW_DEBRIEF,

  LERNIE_START_CHAT,
  LERNIE_STOP_CHAT,

  MAP_HIDE_REGISTER,
  
  MAP_SHOW_ACCOUNT_CREATION,
  MAP_SAVE_ACCOUNT,
  
  MAP_UPDATE_PRE_TALK,
  
  MAP_OPEN_ARENA,
  MAP_CLOSE_ARENA,
  
  MAP_OPEN_EPOCH_GAME,
  MAP_CLOSE_EPOCH_GAME,
  MAP_OPEN_EPOCH_REWARDS,
  MAP_CLOSE_EPOCH_REWARDS,
} from './types';

export const mapLocationFetch = levelId => ({
  type: MAPLOCATION_FETCH,
  payload: levelId,
});

export const mapLocationFetchSuccess = level => ({
  type: MAPLOCATION_FETCH_SUCCESS,
  payload: level,
})

export const openStartGameDialog = levelData => ({
  type: MAP_OPEN_START_LEVEL_DIALOG,
  payload: levelData,
});
export const mapLocationFetchError = levelData => ({
  type: MAPLOCATION_FETCH_ERROR,
  payload: levelData,
});

export const endLernieChat = () => ({ type: MAP_CLOSE_START_DIALOG });

export const startGame = () => ({ type: MAP_START_GAME });

export const mainGame = () => ({ type: MAP_MAIN_GAME });
export const debriefLevel = () => ({ type: MAP_DEBRIEF_LEVEL });

export const showCardsRewards = () => ({ type: MAP_SHOW_CARDS_REWARDS });
export const closeCardsRewards = () => ({ type: MAP_HIDE_CARDS_REWARDS });

export const startLernieChat = () => ({ type: LERNIE_START_CHAT });
export const closeLernieChat = () => ({ type: LERNIE_STOP_CHAT });

export const fetchCurrentGameRewards = () => ({ type: MAP_FETCH_CURRENT_GAME_REWARDS });

export const fetchCurrentGameRewardsSuccess = (rewards) => ({
  type: MAP_FETCH_CURRENT_GAME_REWARDS_SUCCESS,
  payload: rewards,
});

export const fetchCurrentGameRewardsFail = () => ({ type: MAP_FETCH_CURRENT_GAME_REWARDS_FAIL });

export const nextLevel = () => ({ type: MAP_NEXT_LEVEL });

export const showSuccesses = (addedSuccesses) => ({
  type: MAP_SHOW_SUCCESSES,
  payload: addedSuccesses,
});

export const hideSuccesses = () => ({ type: MAP_HIDE_SUCCESSES });
export const hideRegister = () => ({ type: MAP_HIDE_REGISTER });

export const saveAccount = () => ({ type: MAP_SAVE_ACCOUNT });

export const hideRound = () => ({ type: MAP_HIDE_ROUND });

export const showDebrief = () => ({ type: MAP_SHOW_DEBRIEF });

export const showAccountCreation = () => ({ type: MAP_SHOW_ACCOUNT_CREATION });

export const updateMapPreTalkWith = (newTalk) => ({ type: MAP_UPDATE_PRE_TALK,
  payload: newTalk,
});

export const openMapArena = () => ({ type: MAP_OPEN_ARENA });
export const closeMapArena = () => ({ type: MAP_CLOSE_ARENA });

export const openEpochGame = () => ({ type: MAP_OPEN_EPOCH_GAME });
export const closeEpochGame = () => ({ type: MAP_CLOSE_EPOCH_GAME });
export const openEpochRewards = () => ({ type: MAP_OPEN_EPOCH_REWARDS });
export const closeEpochRewards = () => ({ type: MAP_CLOSE_EPOCH_REWARDS });
