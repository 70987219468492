import { connect } from 'react-redux';
import {
    actions as mapActions,
    selectors as mapSelectors,
} from 'features/map';


const mapStateToProps = state => ({
    map: mapSelectors.getCurrentMap(state),
});

const mapDispatchToProps = {
    fetchCurrentGameRewards: mapActions.fetchCurrentGameRewards,
    closeEpochRewards: mapActions.closeEpochRewards,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;