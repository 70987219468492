import React from 'react';
import styles from './StrengthBar.module.scss';

const StrengthBar = ({ value }) => (
    <div className={styles.strengthBar}>
        <span className={styles.strengthBarText}>
            {value}
        </span>
    </div>
);

export default StrengthBar;
