import { withStateHandlers } from 'recompose';
import { endEpochGame } from 'services/http';

const MAX_LIVES = 3;
const STARTING_STRENGTH = 1;

const initialState = () => ({
    checkedCategoryId: undefined,
    foundAnswer: undefined,
    lives: MAX_LIVES,
    strength: STARTING_STRENGTH,
    score: 0,
    areDatesBlurred: false,
    showEndGameModal: false,
    scoresList: [],
    critTable: [],
    nbCardsFound: 0,
});

const handlers = {
    initGame: () => (epochs, firstCard) => ({
        epochs,
        currentCard: {
            ...firstCard,
            displayType: 'mini',
        }
    }),
    updateEpochCard: () => (nextCard) => ({
        currentCard: {
            ...nextCard,
            displayType: 'mini',
        }
    }),
    displayOk: (state, props) => (currentCategory) => {
        // const isCritical = Math.random()*20 < 1
        const isCritical = state.critTable[state.nbCardsFound];
        const pointsForCard = Math.floor(
            (10 + state.strength) / 3                       // strength weight
            * (5 + ((state.lives+1) * (state.lives+1)))/10  // lives weight
            * 1.61803398875                                 // funny weight
            * (isCritical ? 2 : 1)                          // critical weight
        );                       
        return {
            checkedCategoryId: currentCategory,
            foundAnswer: true,
            strength: state.strength + 1,
            score: state.score + pointsForCard,
            areDatesBlurred: state.strength > 5,
            scoresList: [...state.scoresList, {value: pointsForCard, type: isCritical ? 'critical': 'normal'}],
            nbCardsFound: state.nbCardsFound + 1,
        }
    },
    endGame: () => () => {
        endEpochGame();
        return {
            showEndGameModal: true
        };
    },
    displayMissed: (state, props) => (currentCategory) => {
        if (state.lives === 0) {
            endEpochGame();
            return { showEndGameModal: true };
        }
        return {
            checkedCategoryId: currentCategory,
            foundAnswer: false,
            lives: state.lives - 1,
            strength: Math.max(1, state.strength - 1),
            areDatesBlurred: Math.max(1, state.strength - 1) > 5,
        };
},
}

export default withStateHandlers(
    initialState,
    handlers,
);
