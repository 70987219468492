import { compose } from 'recompose';
import Chronometer from './Chronometer.design';
import withConnnect from './Chronometer.withConnect';

import ChronometerStateHandlers from './Chronometer.statehandlers';
import ChronometerLifeCycle from './Chronometer.lifecycle';

export default compose(
    withConnnect,
    ChronometerStateHandlers,
    ChronometerLifeCycle
)(Chronometer);
