import { createReducer } from '@reduxjs/toolkit';

import initialState from './initialState';

import { types } from '.';
import { types as roundTypes } from 'features/round';

import gameStrengths from '../../configuration/gameStrengths';

const playerReducer = createReducer(initialState, {
  [types.INCREASE_DIFFICULTY_LEVEL]: (state, action) => ({ ...state, gameStrength: action.payload }),
  [types.PLAYER_FETCH_SUCCESS]: (state, action) => ({ ...state, ...action.payload }),
  [types.PLAYER_FETCH_FAILURE]: (state, action) => {
    console.error(action.error);
    return state;
  },
  [roundTypes.ROUND_OUT_OF_LIVES]: state => ({ ...state, gameStrength: gameStrengths[state.gameStrength].previousLevel }),
  [roundTypes.ROUND_OUT_OF_ENERGY]: state => ({ ...state, gameStrength: gameStrengths[state.gameStrength].previousLevel }),
});

export default playerReducer;
