import React from 'react';

import styles from './CardType.module.scss';

const themes = {
  art: {
    style: styles.cardTypeArt,
    caption: 'Art',
  },
  balance: {
    style: styles.cardTypeBalance,
    caption: 'Équilibre'
  },
  birth: {
    style: styles.cardTypeBirth,
    caption: 'Naissance',
  },
  brotherhood: {
    style: styles.cardTypeBrotherhood,
    caption: 'Fraternité',
  },
  celebration: {
    style: styles.cardTypeCelebration,
    caption: 'Fête',
  },
  beginning: {
    style: styles.cardTypeBeginning,
    caption: 'Commencement',
  },
  communication: {
    style: styles.cardTypeCommunication,
    caption: 'Communication',
  },
  conquest: {
    style: styles.cardTypeConquest,
    caption: 'Conquête',
  },
  constraint: {
    style: styles.cardTypeConstraint,
    caption: 'Contrainte',
  },
  courage: {
    style: styles.cardTypeCourage,
    caption: 'Courage',
  },
  craft: {
    style: styles.cardTypeCraft,
    caption: 'Ingénierie',
  },
  culture: {
    style: styles.cardTypeCulture,
    caption: 'Culture',
  },
  cycle: {
    style: styles.cardTypeCycle,
    caption: 'Cycle',
  },
  death: {
    style: styles.cardTypeDeath,
    caption: 'Mort',
  },
  destruction: {
    style: styles.cardTypeDestruction,
    caption: 'Destruction',
  },
  defense: {
    style: styles.cardTypeDefense,
    caption: 'Défense',
  },
  earth: {
    style: styles.cardTypeEarth,
    caption: 'Terre',
  },
  economics: {
    style: styles.cardTypeEconomics,
    caption: 'Économie',
  },
  ego: {
    style: styles.cardTypeEgo,
    caption: 'Égo',
  },
  elder: {
    style: styles.cardTypeElder,
    caption: 'Ancêtre',
  },
  emotion: {
    style: styles.cardTypeEmotion,
    caption: 'Émotion',
  },
  energy: {
    style: styles.cardTypeEnergy,
    caption: 'Énergie',
  },
  evolution: {
    style: styles.cardTypeEvolution,
    caption: 'Évolution',
  },
  fire: {
    style: styles.cardTypeFire,
    caption: 'Feu',
  },
  foundations: {
    style: styles.cardTypeFoundations,
    caption: 'Fondations',
  },
  game: {
    style: styles.cardTypeMaster,
    caption: 'Jeu',
  },
  gift: {
    style: styles.cardTypeGift,
    caption: 'Don',
  },
  group: {
    style: styles.cardTypeGroup,
    caption: 'Groupe',
  },
  guide: {
    style: styles.cardTypeGuide,
    caption: 'Guide',
  },
  health: {
    style: styles.cardTypeHealth,
    caption: 'Santé',
  },
  justice: {
    style: styles.cardTypeJustice,
    caption: 'Justice',
  },
  life: {
    style: styles.cardTypeLife,
    caption: 'Vie',
  },
  lifeGiver: {
    style: styles.cardTypeLifeGiver,
    caption: 'Géniteur',
  },
  man: {
    style: styles.cardTypeMan,
    caption: 'Humain',
  },
  master: {
    style: styles.cardTypeMaster,
    caption: 'Maître',
  },
  money: {
    style: styles.cardTypeMoney,
    caption: 'Argent',
  },
  order: {
    style: styles.cardTypeOrder,
    caption: 'Ordre',
  },
  power: {
    style: styles.cardTypePower,
    caption: 'Pouvoir',
  },
  protection: {
    style: styles.cardTypeProtection,
    caption: 'Protection',
  },
  prosperity: {
    style: styles.cardTypeProsperity,
    caption: 'Prospérité',
  },
  scandale: {
    style: styles.cardTypeScandale,
    caption: 'Scandale',
  },
  society: {
    style: styles.cardTypeSociety,
    caption: 'Société',
  },
  source: {
    style: styles.cardTypeSource,
    caption: 'Source',
  },
  stuck: {
    style: styles.cardTypeStuck,
    caption: 'Immobilité',
  },
  sport: {
    style: styles.cardTypeMaster,
    caption: 'Sport',
  },
  secret: {
    style: styles.cardTypeSecret,
    caption: 'Secrets',
  },
  science: {
    style: styles.cardTypeScience,
    caption: 'Connaissances',
  },
  sky: {
    style: styles.cardTypeSky,
    caption: 'Ciel',
  },
  strength: {
    style: styles.cardTypeStrength,
    caption: 'Force',
  },
  sun: {
    style: styles.cardTypeSun,
    caption: 'Soleil',
  },
  trade: {
    style: styles.cardTypeTrade,
    caption: 'Commerce',
  },
  transformation: {
    style: styles.cardTypeTransformation,
    caption: 'Mutation',
  },
  travel: {
    style: styles.cardTypeTravel,
    caption: 'Voyage',
  },
  treaty: {
    style: styles.cardTypeTreaty,
    caption: 'Traité',
  },
  war: {
    style: styles.cardTypeWar,
    caption: 'Guerre',
  },
  wisdom: {
    style: styles.cardTypeWisdom,
    caption: 'Sagesse',
  },
}

const themeToStyle = theme => {
  if (!themes[theme]) {
    console.error(theme);
  }
  return themes[theme].style;
}
const themeToCaption = theme => themes[theme].caption;


const CardType = (props) => {
  const {theme} = props
  return (
    <div className={styles.cardType}>
      <div className={themeToStyle(theme)}></div>
      <div className={styles.cardTypeCaption}>{themeToCaption(theme)}</div>
    </div>
  )
}

export default CardType;