import { withProps } from 'recompose';

export default withProps(
    ({ player, map, round, moveCardsFromTo }) => ({
        areDatesBlurred:
            !round.showMissMessage
            && (
                map?.forcedHiddenDates
                || (map?.forcedGameStrength ?? player.gameStrength) > 2
            ),
        gameStrength: map?.forcedGameStrength ?? player.gameStrength,
        moveCardsFromTo: moveCardsFromTo,
    })
);
