import { connect } from 'react-redux';

import { actions as campaignGameActions } from 'features/campaignGame';
import { selectors as mapSelectors } from 'features/map';
import { selectors as playerSelectors } from 'features/player';
import {
  actions as roundActions, 
  selectors as roundSelectors
} from 'features/round';

const mapStateToProps = (state) => ({
  map: mapSelectors.getCurrentMap(state),
  player: playerSelectors.getPlayerSelector(state),
  round: roundSelectors.getRoundParams(state),
});

const mapDispatchToProps = {
  handleFoundOrder: roundActions.handleFoundOrder,
  handleWrongOrder: roundActions.handleWrongOrder,
  handleOutOfTime: roundActions.handleOutOfTime,
  handleOutOfEnergy: roundActions.handleOutOfEnergy,
  campaignGameInit: campaignGameActions.campaignGameInit,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withConnect;
