import React from 'react';

import { WizardModal } from 'components';

import styles from './EpochGameRewards.module.scss';

const EpochGameRewards = props => {
  const { map, closeEpochRewards, modalSteps } = props;
  const cards = map?.rewards?.cardsDetails;
  if (!cards) {
    return null;
  }

  return (
    <div className={styles.background}>
      <WizardModal
        onCloseModal={closeEpochRewards}
        stepsDesign={modalSteps}
      />
      <div className={styles.cardBoardOverlay} />
    </div>
  );
};

export default EpochGameRewards;
