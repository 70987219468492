import { withStateHandlers } from 'recompose';

const initialState = () => ({
    step: 0,
    name: '',
    email: '',
    password1: '',
    password2: '',
    nameTooShort: true,
    nameAlreadyTaken: false,
    emailAlreadyRegistered: false,
    isPlayerRegistered: false,
    isValidEmail: false,
    isValidPassword1: false,
    isValidPassword2: false,
});

const VALID_EMAIL_PATTERN = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
const FORBIDDEN_CHARS = /[^0-9a-zA-Z$€\-_+ éèïäöüêùàôâûî@'.]/;
const BEGINS_WITH_LETTER = /^[a-zA-Z]/;
const NAME_MINIMAL_LENGTH = 3;
const NAME_MAXIMAL_LENGTH = 20;

const PASSWORD_MINIMAL_LENGTH = 8;
const PASSWORD_MAXIMAL_LENGTH = 15;
const isNameTooShort = name => name.length < NAME_MINIMAL_LENGTH;

const FORBIDDEN_CHARS_EMAIL = /[^0-9a-zA-Z@.]/;

const isValidEmail = emailAddress => new RegExp(VALID_EMAIL_PATTERN).test(emailAddress);
const isValidPassword = password => 
    password.length >= PASSWORD_MINIMAL_LENGTH 
    && password.length <= PASSWORD_MAXIMAL_LENGTH
    && RegExp(/[A-Z]/).test(password)
    && RegExp(/[a-z]/).test(password)
    && RegExp(/[0-9]/).test(password);

const handlers = {

    setName: () => e => {
        const name = e.target.value;
        const nameTooShort = isNameTooShort(name);
        if (
            !name.match(FORBIDDEN_CHARS)
            && (name.match(BEGINS_WITH_LETTER) || !name)
            && (name.length <= NAME_MAXIMAL_LENGTH)
        ){
            return {
                name,
                nameTooShort,
                nameAlreadyTaken: false,
            };
        }
        return {
            nameTooShort, 
        }
    },
    setEmail: () => e => {
        const email = e.target.value;
        if (
            !email.match(FORBIDDEN_CHARS_EMAIL)
        ) {
            return {
                email,
                isValidEmail: isValidEmail(email),
                emailAlreadyRegistered: false,
            };
        }
        return {
            isValidEmail: isValidEmail(email),
        }
    },

    setPassword1: () => e => {
        const password1 = e.target.value;
        return {
            password1,
            isValidPassword1: isValidPassword(password1),
        }
    },
    setPassword2: () => e => {
        const password2 = e.target.value;
        return {
            password2,
            isValidPassword2: isValidPassword(password2),
        }
    },
    updateStateHandlerProps: () => updatedState => ({...updatedState}),
    previousStep: ({step}) => () => {
        return {
            step: step-1,
        };
    },
};

export default withStateHandlers(
    initialState,
    handlers,
);
