import React from 'react';
import ReactDOM from 'react-dom';

import styles from './Arena.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Card = ({ text }) => (
  <div className={styles.fakeCard}>
    {text}
  </div>
)

const portal = document.getElementsByClassName('drag-drop-portal')[0];

const PortalAwareItem = ({
  provided,
  snapshot,
  text,
}) => {

  const usePortal = snapshot.isDragging;
  const child = (<div
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    className={usePortal ? styles.portalContainer : styles.portalContainerOff}
  >
    <Card text={text}>

    </Card>

  </div>);
  return usePortal ?
  ReactDOM.createPortal(child, portal) :
  child;
}

const Arena = props => {
  return (
    <div className={styles.arenaFullPage}>
      <DragDropContext>
        <div className={styles.cardBoardBackground}>
          <div
            className={styles.cardBoard}
          >
            <Droppable
              droppableId="row1"
              direction="horizontal"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef} className={snapshot.isDraggingOver ? styles.cardsRowIsDragging : styles.cardsRowAnimate}
                >
                  <Draggable index={0} draggableId="card1">{(provided, snapshot) => <PortalAwareItem provided={provided} snapshot={snapshot} text={'Card 1'} />}</Draggable>
                  <Draggable index={1} draggableId="card2">{(provided, snapshot) => <PortalAwareItem provided={provided} snapshot={snapshot} text={'Card 2'} />}</Draggable>
                  <Draggable index={2} draggableId="card3">{(provided, snapshot) => <PortalAwareItem provided={provided} snapshot={snapshot} text={'Card 3'} />}</Draggable>
                  <Draggable index={3} draggableId="card4">{(provided, snapshot) => <PortalAwareItem provided={provided} snapshot={snapshot} text={'Card 4'} />}</Draggable>
                  <Draggable index={4} draggableId="card5">{(provided, snapshot) => <PortalAwareItem provided={provided} snapshot={snapshot} text={'Card 5'} />}</Draggable>
                </div>
              )}
            </Droppable>

          </div>
        </div>
      </DragDropContext>
    </div>
  );
};


export default Arena;