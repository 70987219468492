import { compose } from 'recompose';

import SortGameCampaign from './SortGameCampaign.design';
import withConnect from './SortGameCampaign.connect';
import withStateHandlers from './SortGameCampaign.stateHandlers';
import withLifeCycle from './SortGameCampaign.lifecycle';

export default compose(
    withConnect,
    withStateHandlers, 
    withLifeCycle,
)(SortGameCampaign);
