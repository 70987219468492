import React from 'react';

import styles from './Chronometer.module.scss';

const addZero = n => (n < 10 ? '0' + n : n);

const Chronometer = props => {
  const seconds = props.counter / 1000;
  const milliseconds = props.counter % 1000;

  const displayedMS = addZero(Math.floor(milliseconds / 10));
  const displayedSeconds = addZero(Math.floor(seconds % 60));
  const displayedMinutes = addZero(Math.floor(seconds / 60));
  return (
    <div className={styles.chronometer}>
      <span className={styles.chronometerText}>
        {displayedMinutes}:{displayedSeconds}:{displayedMS}
      </span>
    </div>
  );
};

export default Chronometer;
