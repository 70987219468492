import React from 'react';

import { Draggable } from 'react-beautiful-dnd';

import { PortalAwareCard } from 'components';

const EpochGameDistributor = ({ card }) => (
    <Draggable
      index={0}
      draggableId={card._id}
      key={card._id}
    >
      {
        (provided, snapshot) =>
          <PortalAwareCard
            provided={provided}
            snapshot={snapshot}
            card={card}
            index={0}
            areDatesBlurred={card.areDatesBlurred}
            nbCards={1}
            round={{}}
          />
      }
    </Draggable>
);

export default EpochGameDistributor;