import { withStateHandlers } from 'recompose';

const initialState = () => ({
    step: 0,
});

const handlers = {
    updateStateHandlerProps: () => updatedState => ({...updatedState}),
    previousStep: ({step}) => () => {
        return {
            step: step - 1,
        };
    },
    nextStep: ({step}) => () => {
        return {
            step: step + 1,
        };
    },
};

export default withStateHandlers(
    initialState,
    handlers,
);
