import { lifecycle } from 'recompose';

const imagesToPreload = [
    '/blackboard.jpg',
    '/ui/map.jpg',
    '/lernie-481px.png',
    '/ui/background-green.png',
    '/ui/bubble-red-2.png',
    '/ui/bubble-green-2.png',
    '/ui/bubble-blue-2.png',
    '/ui/main-dialog.png',
    '/ui/main-dialog-no-button.png',
    '/ui/top-dialog-no-button.png',
];


export default lifecycle({
    componentDidMount() {
        this.props.fetchPlayerInformationsWithMap();
        window.preloadedPictures = imagesToPreload.map(picturePath => {
            const img = new Image();
            img.src = picturePath;
            return img;
        });
        this.props.setImagesLoaded();
    }
});